import { a, useSpring } from '@react-spring/three'
import { Html, useGLTF } from '@react-three/drei'
import { Suspense, useEffect } from 'react'
import { DoubleSide, Mesh } from 'three'
import { useMounted } from '../../../../../hooks/useMounted'
import { ImageButton } from '../../../../shared/ImageButton'
import { useViewVisible } from './hooks'

const _Shop = () => {
  const inShop = useViewVisible('shop')
  const {
    nodes: { ShopDoor },
  } = useGLTF('/assets/3d/rooms/shop/door.glb') as unknown as {
    nodes: Record<string, Mesh>
  }
  const isMounted = useMounted(500)
  const { intensity, rotation } = useSpring({
    rotation: isMounted && inShop ? 0 : -Math.PI / 2,
    intensity: isMounted && inShop ? 0.8 : 0,
    delay: inShop ? 500 : 0,
  })

  useEffect(() => {
    ShopDoor.geometry.center()
  }, [ShopDoor])
  return (
    <group position={[-39.25, 1.7, 8.25]}>
      <mesh rotation={[0, Math.PI / 4, 0]}>
        <planeBufferGeometry args={[1.75, 2.25]} />
        <meshBasicMaterial
          color='red'
          opacity={0}
          transparent
          side={DoubleSide}
        />
        {/* @ts-ignore */}
        <a.pointLight position={[-0.2, 0, 0.7]} color={0xffffff} intensity={intensity} distance={20} />
      </mesh>
      <a.group
        position={[0.7, 0, -0.7]}
        rotation={rotation.to((r) => [0, r, 0]) as any}
      >
        <mesh
          position={[0.7, 0, 0.7]}
          scale={ShopDoor.scale}
          geometry={ShopDoor.geometry}
          material={ShopDoor.material}
        />
      </a.group>
      <Html
        pointerEvents={inShop ? 'auto' : 'none'}
        position={[-0.2, 0, 0.2]}
        rotation={[0, Math.PI * (5 / 4), 0]}
        transform
        center
      >
        <ImageButton
          style={{
            transition: `opacity 500ms ease ${isMounted && inShop ? 500 : 0}ms`,
            opacity: isMounted && inShop ? 1 : 0,
          }}
          height={50}
          width={100}
          onClick={() => window.open('https://shop.whyloneworld.com', '_blank')
          }
          url='/assets/images/buttons/shop.gif'
          alt='SHOP'
        />
      </Html>
    </group>
  )
}

export const Shop = () => (
  <Suspense>
    <_Shop />
  </Suspense>
)
