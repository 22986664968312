import { GestureToController, SwipeGesture } from '../../types'

const SWIPE_THRESHOLD = 0.015

// bin search for target
const getCurrTarget = (value: number, targets: number[]) => targets.reduce(
  (prev, target, index) => (
    Math.abs(target - value) < Math.abs(prev.target - value)
      ? ({ target, index })
      : prev
  ),
  { index: 0, target: targets[0] },
)
export const getSwipeGesture: GestureToController<SwipeGesture> = (
  bit,
) => ({
  subscribe: ({ x, onChange, initialValue }) => {
    const { horizontal, speed = 1, targets } = bit
    let touchStart: number | null
    let xStart: number | null
    // let currTarget = { target: 0, index: 0 };
    let delta: number = 0

    const handleTouchStart = ({ touches }: TouchEvent) => {
      if (touches.length === 1) {
        const { clientX, clientY } = touches[0]
        // currTarget = getCurrTarget(x.get(), targets);
        touchStart = horizontal ? clientX : clientY || 0
        xStart = x.get() || 0
        delta = 0
        x.stop()
      }
    }

    const handleTouchMove = ({ touches }: TouchEvent) => {
      if (xStart !== null && touchStart !== null && touches.length === 1) {
        const { clientX, clientY } = touches[0]

        delta = Math.max(
          -SWIPE_THRESHOLD * 1.5,
          Math.min(
            SWIPE_THRESHOLD * 1.5,
            (horizontal ? touchStart - clientX : touchStart - clientY) * (speed / 10000),
          ),
        )
        x.set(xStart + delta)
      }
    }
    const handleTouchEnd = () => {
      const { index } = getCurrTarget(x.get(), targets)
      let goToIndex = index
      if (delta > SWIPE_THRESHOLD && index < targets.length - 1) {
        goToIndex = index + 1
        const goTo = targets[goToIndex]
        onChange(goTo)
      } else if (delta < -SWIPE_THRESHOLD && index !== 0) {
        goToIndex = index - 1
        const goTo = targets[goToIndex]
        onChange(goTo)
      } else {
        x.start(initialValue)
      }
      touchStart = null
    }

    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchmove', handleTouchMove)
    window.addEventListener('touchend', handleTouchEnd)
    return () => {
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchmove', handleTouchMove)
      window.removeEventListener('touchend', handleTouchEnd)
    }
  },
})
