import {
  FileField,
  FileValue,
  ImageMimeType,
  OnUploadProgress,
  UploadFileFunc,
} from '@loneworld/shared'
import {
  getDownloadURL, getStorage, ref, uploadBytesResumable,
} from 'firebase/storage'
import { app } from './app'

export const storage = getStorage(app)

const uploadFormFile = async (
  onUploadProgress: OnUploadProgress,
  basePath: string,
  fieldPath: string,
  field: FileField,
  data?: FileValue | undefined,
): Promise<FileValue | undefined> => {
  const { title } = data || {}
  const { placeholder } = field || {}
  if (!data?.dataUrl) return data
  const blob = await fetch(data.dataUrl).then((res) => res.blob())

  const storageRef = ref(storage, `${basePath}/${fieldPath.replace(/\./g, '/')}`)
  const uploadTask = uploadBytesResumable(storageRef, blob)
  uploadTask.on('state_changed', (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    onUploadProgress(progress)
  })

  return new Promise((resolve, reject) => {
    uploadTask
      .then(async ({ metadata, ref: uploadRef }) => getDownloadURL(uploadRef).then((downloadUrl) => {
        const fileValue: FileValue = {
          ...data,
          title: title || placeholder || '',
          type: metadata.contentType as ImageMimeType,
          url: downloadUrl,
        }
        delete fileValue.dataUrl
        resolve(fileValue)
      }))
      .catch(reject)
  })
}

export const getUploadFormFile = (
  onUploadProgress: OnUploadProgress,
): UploadFileFunc => (
  basePath,
  fieldPath,
  field,
  data,
) => uploadFormFile(onUploadProgress, basePath, fieldPath, field, data)
