import {
  Box, BoxProps, Text, TextProps,
} from '@chakra-ui/react'
import './styles.css'

export const GradientText = ({
  children, color, background, boxProps, ...props
}: TextProps & {boxProps?: BoxProps}) => (
    <Box background={background} {...boxProps} >
    <Text
      // @ts-ignore
      cursor='default'
      className='gradient-text'
      background={color}
      {...props}
    >
      {children}
    </Text>
    </Box>
)
