/* eslint-disable no-param-reassign */
import {
  MeshLambertMaterial, Texture, TextureLoader, Vector2,
} from 'three'
import CustomShaderMaterial from 'three-custom-shader-material/vanilla'
import { fragmentShaderSource, vertexShaderSource } from './shaders'

export class RippleShader {
  material: CustomShaderMaterial

  private time: number = 0

  private currentTexture: number = 0

  private textureStack: Texture[] = []

  private loading: boolean = false

  constructor(initialTextureUrl?: string) {
    const initialTexture0 = new Texture()
    const initialTexture1 = new Texture()
    this.textureStack = [initialTexture0, initialTexture1]
    const uniforms = {
      texture0: { type: 'sampler2D', value: initialTexture0 },
      texture1: { type: 'sampler2D', value: initialTexture1 },
      currentTexture: { type: 'int', value: 0 },
      fadeCenter: { type: 'vec2', value: new Vector2(0.5, 0.5) },
      fadeRadius: { type: 'float', value: 0.2 },
      fadeAmount: { type: 'float', value: 0.5 },
      time: { type: 'float', value: 0 },
      fadeStartTime: { type: 'float', value: 0 },
      loadingStartTime: { type: 'float', value: 0 },
      loadingEndTime: { type: 'float', value: 0 },
    }

    this.material = new CustomShaderMaterial({
      baseMaterial: MeshLambertMaterial,
      uniforms,
      vertexShader: vertexShaderSource,
      fragmentShader: fragmentShaderSource,
    })

    this.setRipple.bind(this)
    this.updateTexture.bind(this)
    this.update.bind(this)
    if (initialTextureUrl) {
      this.updateTexture(initialTextureUrl, new Vector2(0.5, 0.5))
    }
  }

  public setRipple(position: Vector2, radius: number, amount: number) {
    this.material.uniforms.fadeCenter.value = position
    this.material.uniforms.fadeRadius.value = radius
    this.material.uniforms.fadeAmount.value = amount
  }

  public updateTexture(textureUrl: string, position: Vector2) {
    if (this.loading) return
    this.loading = true
    this.material.uniforms.loadingStartTime.value = this.time
    this.material.uniforms.fadeCenter.value = position
    new TextureLoader().load(
      textureUrl,
      (texture) => {
        this.loading = false
        // texture.encoding = sRGBEncoding
        // texture.colorSpace
        texture.needsUpdate = true
        if (this.currentTexture === 0) {
          this.material.uniforms.texture1.value = texture
        } else {
          this.material.uniforms.texture0.value = texture
        }
        this.currentTexture = this.currentTexture === 0 ? 1 : 0
        this.material.uniforms.currentTexture.value = this.currentTexture
        this.material.uniforms.fadeStartTime.value = this.time
        this.material.uniforms.loadingEndTime.value = this.time
        this.textureStack.push(texture)
        if (this.textureStack.length > 2) {
          const disposed = this.textureStack.shift()
          disposed?.dispose()
        }
      },
    )
  }

  public update(dT: number) {
    this.time += dT
    this.material.uniforms.time.value = this.time
  }
}
