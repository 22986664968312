import { IUniform, Texture } from 'three'

export const vShader = `
void main() {
  gl_Position = vec4(position, 1.0);
}
`
export const fShader = `
#ifdef FXAA
#pragma glslify: fxaa = require(glsl-fxaa)
#endif

varying vec2 v_texCoord0;

uniform vec2 resolution;
uniform sampler2D mainTexture;
uniform sampler2D fadeTexture;

uniform int useTexture;
uniform sampler2D tMixTexture;
uniform float threshold;

uniform float mixRatio;

void main() {

#ifdef FXAA
  vec4 texel1 = fxaa(mainTexture, gl_FragCoord.xy, resolution);
  vec4 texel2 = fxaa(fadeTexture, gl_FragCoord.xy, resolution);
#else
  vec2 uv = gl_FragCoord.xy / resolution;
  vec4 texel1 = texture2D(mainTexture, uv);
  vec4 texel2 = texture2D(fadeTexture, uv);
#endif

if(useTexture == 1){
  
  vec4 transitionTexel = texture2D( tMixTexture, uv );
  float r = mixRatio * (1.0 + threshold * 2.0) - threshold;
  float mixf = clamp((transitionTexel.r - r)*(1.0/threshold), 0.0, 1.0);

  // gl_FragColor = vec4(1.0,0.0,0.0,1.0);

  gl_FragColor = mix( texel1, texel2, mixf );
} else {
  vec4 finalTexture = mix(texel2, texel1, mixRatio);
  gl_FragColor = finalTexture;
}
}
  `
export const getCanvasUniforms = ({
  fadeTexture,
  mainTexture,
  resolution,
  mixTexture,
  threshold = 0.5,
}: {
  fadeTexture: Texture
  mainTexture: Texture
  resolution: [number, number]
  mixTexture?: Texture
  threshold?: number
}): Record<string, IUniform> => {
  const u = {
    mainTexture: {
      value: mainTexture,
    },
    fadeTexture: {
      value: fadeTexture,
    },
    mixRatio: {
      value: 1,
    },
    resolution: {
      value: resolution,
    },
    threshold: {
      value: threshold,
    },
    tMixTexture: {
      value: mixTexture,
    },
    useTexture: {
      value: mixTexture ? 1 : 0,
    },
  }
  return u
}

export const testFShader = `
  uniform vec2 resolution;
  void main(){
    vec2 uv = gl_FragCoord.xy / resolution;
    gl_FragColor = vec4(uv, 0., 1.);
  }
`
