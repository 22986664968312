import { a, useSpring } from '@react-spring/three'
import { Html } from '@react-three/drei'
import { useMounted } from '../../../../../hooks/useMounted'
import { ImageButton } from '../../../../shared/ImageButton'
import { useViewVisible } from './hooks'

const links: Array<{ url: string; imageName: string }> = [
  {
    url: 'mailto:zac@whyloneworld.com',
    imageName: 'email',
  },
  {
    url: 'https://twitter.com/whylonewolf',
    imageName: 'twitter',
  },
  {
    url: 'https://www.youtube.com/channel/UCtLgQnGkWe74dukALaUNt1',
    imageName: 'yt',
  },
  {
    url: 'https://www.instagram.com/whylonewolf',
    imageName: 'ig',
  },
  {
    url: 'https://discord.gg/DxSV3CVa69',
    imageName: 'discord',
  },
]

export const Contact = () => {
  const active = useViewVisible('contact')
  const mounted = useMounted(500)
  const { intensity } = useSpring({ intensity: mounted && active ? 1 : 0, delay: active ? 500 : 0 })
  return (
    <group position={[-22.5, 1.9, 7]}>
      <Html
        transform
        center
        rotation={[0, Math.PI, 0]}
        scale={[0.25, 0.25, 0.25]}
      >
        <div
          style={{ transition: `opacity 1000ms ease ${mounted && active ? 500 : 0}ms` }}
          className={`contact${mounted && active ? '' : ' hidden'}`}
        >
          {links.map(({ imageName, url }) => (
            <ImageButton
              key={imageName}
              url={`/assets/images/social/${imageName}.gif`}
              alt={imageName}
              height={200}
              width={200}
              onClick={() => window.open(url, '_blank')}
            />
          ))}
          <ImageButton
            url='/assets/images/help.gif'
            alt='help'
            height={200}
            width={200}
            onClick={() => {
              // createModal({ children: [InterviewsModal()] })
            }}
          />
        </div>
      </Html>
      {/* @ts-ignore */}
      <a.pointLight
        position={[0, 0, -1]}
        color={0xfffeee}
        intensity={intensity}
        distance={15}
      />
      <pointLight
        position={[0, 1, -1]}
        color={0xff4411}
        intensity={2}
        distance={5}
      />
    </group>
  )
}
