import {
  AddIcon, CheckIcon, CloseIcon, DeleteIcon, RepeatIcon,
} from '@chakra-ui/icons'
import {
  Box,
  Button,
  IconButton,
  IconButtonProps,
  Image,
} from '@chakra-ui/react'
import { formatDateTime } from '@loneworld/shared'
import { useWithLoading } from 'hooks/useWithLoading'
import { useCallback, useState } from 'react'
import { CollapseHorizontal } from './CollapseHorizontal'
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog'
import { CoolTooltip } from './CoolTooltip'
import { DiscardChangesDialog } from './DiscardChangesDialog'

type TooltipButtonProps = Omit<IconButtonProps, 'aria-label' | 'onClick'> & {
  label?: string
  onClick?: (() => Promise<void>) | (() => void)
}

export const CloseButton = ({
  onClick,
  shouldConfirm,
  ...props
}: TooltipButtonProps & { shouldConfirm?: boolean }) => {
  const { loading, call } = useWithLoading(onClick)

  const [confirming, setConfirming] = useState(false)
  const handleClick = useCallback(() => {
    if (shouldConfirm && !confirming) setConfirming(true)
    else {
      setConfirming(false)
      call()
    }
  }, [shouldConfirm, call, confirming])
  return (
    <CoolTooltip
      label={props.label || 'CLOSE'}
    >
      <Box>
        <IconButton
          // @ts-ignore
          aria-label='Close'
          fontFamily='Inter'
          isLoading={loading}
          size='sm'
          boxShadow='md'
          bg='whiteAlpha.400'
          _hover={{ bg: 'red.400' }}
          opacity={0.8}
          icon={<CloseIcon width={props.size === 'xs' ? 3 : 4} color='white' opacity={0.6} />}
          fontSize='1rem'
          onClick={handleClick}
          {...props}
        />
        {shouldConfirm ? (
          <DiscardChangesDialog
            isOpen={confirming}
            onCancel={() => setConfirming(false)}
            onConfirm={() => {
              setConfirming(false)
              call()
            }}
          />
        ) : null}
      </Box>
    </CoolTooltip>
  )
}

export const AddButton = (props: TooltipButtonProps) => (
  <CoolTooltip
    label={props.label || 'ADD'}
  >
    <IconButton
      // @ts-ignore
      aria-label='Add'
      boxShadow='md'
      fontFamily='Inter'
      filter='drop-shadow(0 0 3px black)'
      size='sm'
      bg='whiteAlpha.500'
      _hover={{ bg: 'green.400' }}
      opacity={0.8}
      icon={<AddIcon color='white' opacity={0.6} />}
      fontSize='1rem'
      {...props}
    />
  </CoolTooltip>
)

export const LoadButton = (props: TooltipButtonProps) => (
  <CoolTooltip
    label={props.label || 'LOAD'}
  >
    <IconButton
      // @ts-ignore
      aria-label='Load'
      boxShadow='md'
      size='sm'
      bg='whiteAlpha.500'
      _hover={{ bg: 'whiteAlpha.600' }}
      opacity={0.8}
      icon={
        <Image
          opacity={0.6}
          filter='invert(100%)'
          src='/assets/images/history.svg'
        />
      }
      {...props}
    />
  </CoolTooltip>
)

export const SaveButton = ({
  savedOn,
  ...props
}: TooltipButtonProps & { savedOn?: string | number | null }) => (
  <CoolTooltip
    label={savedOn ? `Saved on ${formatDateTime(savedOn)}` : 'SAVE'}
  >
    <IconButton
      // @ts-ignore
      aria-label='Save'
      boxShadow='md'
      size='sm'
      bg='whiteAlpha.500'
      _hover={{ bg: 'blue.300' }}
      opacity={0.8}
      icon={
        savedOn ? (
          <CheckIcon color='green.300' />
        ) : (
          <Image
            filter='invert(100%)'
            width='24px'
            opacity={0.7}
            src='/assets/images/save.svg'
          />
        )
      }
      {...props}
    />
  </CoolTooltip>
)

export const DeleteButton = ({
  onClick,
  itemName,
  shouldConfirm,
  ...props
}: TooltipButtonProps & { shouldConfirm?: boolean, itemName: string }) => {
  const { loading, call } = useWithLoading(onClick)

  const [confirming, setConfirming] = useState(false)
  const handleClick = useCallback(() => {
    if (shouldConfirm) {
      if (!confirming) setConfirming(true)
      else {
        setConfirming(false)
        call()
      }
    } else call()
  }, [call, confirming, shouldConfirm])
  return (
    <CoolTooltip
      bg='red.600'
      fontSize='sm'
      label={props.label || 'DELETE'}
    >
      <Box>
        <IconButton
          // @ts-ignore
          aria-label='Delete'
          fontFamily='Inter'
          isLoading={loading}
          size='sm'
          boxShadow='md'
          bg='red.500'
          _hover={{ bg: 'red.400' }}
          opacity={0.8}
          icon={<DeleteIcon color='white' />}
          fontSize='1rem'
          onClick={handleClick}
          {...props}
        />
          <ConfirmDeleteDialog
          itemName={itemName}
            isOpen={confirming}
            onCancel={() => setConfirming(false)}
            onConfirm={() => {
              setConfirming(false)
              call()
            }}
          />
      </Box>
    </CoolTooltip>
  )
}

export const TooltipIconButton = ({
  label,
  ...props
}: Omit<IconButtonProps, 'aria-label'> & { label: string }) => (
  <CoolTooltip
    label={label}
  >
    <IconButton
    // @ts-ignore
      boxShadow='md'
      aria-label={label}
      size='sm'
      bg='whiteAlpha.200'
      color='whiteAlpha.700'
      _hover={{ bg: 'blackAlpha.400' }}
      opacity={0.8}
      {...props}
    />
  </CoolTooltip>
)

export const ResetButton = ({ ...props }: TooltipButtonProps) => (
  <CoolTooltip
    label='RESET'
  >
    <IconButton
      aria-label='Reset'
      boxShadow='md'
      size='sm'
      bg='whiteAlpha.500'
      _hover={{ bg: 'blue.300' }}
      filter='drop-shadow(0 0 3px black)'
      opacity={0.8}
      icon={<RepeatIcon color='whiteAlpha.700' />}
      {...props}
    />
  </CoolTooltip>
)

export const ExpandButton = ({
  onClick,
  width,
  children,
  active,
  ...props
}: TooltipButtonProps & { width: number; active: boolean }) => (
  <CollapseHorizontal width={width} active={active}>
    <Button
      aria-label='Expand'
      ml='auto'
      boxShadow='md'
      size='sm'
      minW='0'
      padding={0}
      width={`${width * 0.95}px`}
      px={2}
      justifyContent='center'
      bg='whiteAlpha.500'
      _hover={{ bg: 'whiteAlpha.600' }}
      filter='drop-shadow(0 0 3px black)'
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  </CollapseHorizontal>
)
