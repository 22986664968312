/* eslint-disable max-len */
import { Center, CircularProgress } from '@chakra-ui/react'
import { a, config, useSpring } from '@react-spring/three'
import { Html } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import {
  forwardRef, useContext, useEffect, useState,
} from 'react'
import YouTube, { YouTubePlayer } from 'react-youtube'
import { OrbitControls } from 'three-stdlib'
import { TheaterContext } from './context'

export const Screen = forwardRef<YouTubePlayer>((_, ref) => {
  const {
    playlist: { selected, selectedVideoId, playerVisible },
    player: { onPlayerReady, onPlayerState },
  } = useContext(TheaterContext)
  const [isDragging, setIsDragging] = useState(false)
  const { z } = useSpring({
    z: playerVisible ? 0 : -4,
    config: config.slow,
  })
  const controls = useThree((s) => s.controls) as OrbitControls | undefined
  useEffect(() => {
    if (!controls) return () => {}
    const onStart = () => {
      setIsDragging(true)
    }
    const onEnd = () => {
      setIsDragging(false)
    }
    controls.addEventListener('start', onStart)
    controls.addEventListener('end', onEnd)
    return () => {
      controls.removeEventListener('start', onStart)
      controls.removeEventListener('end', onEnd)
    }
  }, [controls])
  return (
    <a.mesh scale={[1, 1, 1]} position={z.to((v) => [0, 5.625 - v * 2, 1])}>
      <planeGeometry />
      <meshBasicMaterial transparent opacity={0} color={0xff0000} />
      <Html
        scale={[0.43, 0.43, 0.43]}
        style={{
          userSelect: 'none',
          width: '800px',
          height: '450px',
          pointerEvents: 'none',
        }}
        position={[0, 0, 0]}
        center
        transform
      >
        <Center
          onPointerEnter={() => {
            if (controls) {
              controls.saveState()
              controls.reset()
              controls.update()
            }
          }}
          pointerEvents={
            !isDragging && (playerVisible !== null || playerVisible) !== null ? 'auto' : 'none'
          }
          opacity={playerVisible ? 1 : 0}
          transition='opacity 330ms'
          w='800px'
          h='450px'
          bg='rgba(0,0,0,0.8)'
        >
          <CircularProgress
            pointerEvents='none'
            // opacity={playerState === null ? 1 : 0}
            color='gray'
            position='absolute'
            isIndeterminate
          />
          <YouTube
            onReady={({ target }) => onPlayerReady(target)}
            onStateChange={(e) => onPlayerState(e.data)}
            opts={{ height: '450', width: '800' }}
            style={{
              zIndex: 2,
            }}
            ref={ref}
            videoId={selectedVideoId || selected?.snippet?.resourceId?.videoId}
          />
        </Center>
      </Html>
    </a.mesh>
  )
})
