import { useThree } from '@react-three/fiber'
import { useCallback } from 'react'

export const usePointerHover = (onHover?: () => void, onBlur?: () => void) => {
  const { domElement } = useThree((s) => s.gl)
  const onPointerOver = useCallback(() => {
    if (onHover) onHover()
    domElement.style.cursor = 'pointer'
  }, [domElement, onHover])

  const onPointerOut = useCallback(() => {
    if (onBlur) onBlur()
    domElement.style.cursor = 'auto'
  }, [domElement, onBlur])

  return { onPointerOver, onPointerOut }
}
