import { Text, Button } from '@chakra-ui/react'
import { a, useSpring } from '@react-spring/three'
import { Html, useGLTF, useTexture } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import {
  Suspense, useEffect, useRef,
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Group, Mesh, MeshStandardMaterial, Vector2, Vector3Tuple,
} from 'three'
import { useMounted } from '../../../../../hooks/useMounted'
import { useViewVisible } from './hooks'

const _Record = () => {
  const {
    nodes: { '33_1-3_Record': recordMesh },
  } = useGLTF('/assets/3d/record.glb') as unknown as {
    nodes: Record<string, Mesh>
  }
  const normalMap = useTexture('/assets/3d/textures/Record_normals.jpg')
  const visible = useViewVisible('coming-soon')
  const ref = useRef<Group>(null)
  const mounted = useMounted(500)
  const { intensity } = useSpring({ intensity: visible ? 10 : 0, delay: visible ? 500 : 0 })
  useFrame((_, dT) => {
    if (!ref.current) return
    ref.current.rotation.y += dT / 2
  })

  useEffect(() => {
    const mesh = recordMesh
    if (mesh.isMesh) {
      mesh.geometry.center()
      const mat = mesh.material as MeshStandardMaterial
      // normalMap.wrapS = ClampToEdgeWrapping
      // normalMap.wrapT = ClampToEdgeWrapping
      normalMap.flipY = false
      // normalMap.mapping = Mapping
      normalMap.needsUpdate = true
      mat.normalMap = normalMap
      mat.normalScale = new Vector2(6, 6)
      mat.needsUpdate = true
    }
  }, [recordMesh, normalMap])

  const { position, rotation, scale } = useSpring({
    position: [0, visible ? 1.75 : 1, 0] as Vector3Tuple,
    rotation: visible ? Math.PI : 0,
    scale: (visible ? [7, 7, 7] : [0, 0, 0]) as Vector3Tuple,
  })
  const navigate = useNavigate()
  return (
    <group position={[-14, 1.5, 5]}>
      <Html center transform rotation={[0, Math.PI, 0]} scale={[0.4, 0.4, 0.4]}>
        <Button onClick={() => navigate('/sound')}>
        <Text
          fontSize='2rem'
          color='white'
          userSelect='none'
          transition={`all 500ms ease ${mounted && visible ? 333 : 0}ms`}
          fontFamily='Gloria Hallelujah'
          className={mounted && visible ? '' : 'hidden'}
        >
          enter room
        </Text>
        </Button>
      </Html>
      <group ref={ref}>
        <a.group
          position={position}
          rotation={rotation.to((r) => [Math.PI / 2, r, 0]) as any}
          scale={scale}
        >
          <mesh geometry={recordMesh.geometry} material={recordMesh.material} />
        </a.group>
      </group>
      {/* @ts-ignore */}
      <a.pointLight
        color={0xffccaa}
        position={[0, 2.5, -4]}
        intensity={intensity}
        distance={10}
      />
    </group>
  )
}

export const Record = () => (
  <Suspense>
    <_Record />
  </Suspense>
)
