import {
  Box, Button, Center, CircularProgress, HStack, Text, VStack,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import { popupSignIn } from '../../backend/auth'
import { signOut, useUser } from '../../store/auth'
import { CityConfigView } from './CityConfig'
import { TheaterConfigView } from './TheaterConfig'

const SignedInView = () => (
  <HStack flexDir={['column', 'column', 'row']} spacing={0} h='100%' w='100%'>
    <VStack
      flexDir={['row', 'row', 'column']}
      py={4}
      px={2}
      h={['auto', 'auto', '100%']}
      w={['100%', '100%', '200px']}
      bg='gray.900'
    >
      <VStack flex={1}>
        <Link to='city'>
          <Button size='lg' variant='link'>
            CITY
          </Button>
        </Link>
        <Link to='theater'>
          <Button size='lg' variant='link'>
            THEATER
          </Button>
        </Link>
      </VStack>
      <Box>
        <Button onClick={() => signOut()}>SIGN OUT</Button>
      </Box>
    </VStack>
    <VStack
      flex={1}
      bg='gray.800'
      boxShadow='inset 4px 0 4px rgba(0, 0, 0, 0.5)'
      overflowY='auto'
      h={['auto', 'auto', '100%']}
      w={['100%', '100%', 'auto']}
    >
      <Routes>
        <Route path='city' element={<CityConfigView />} />
        <Route path='theater' element={<TheaterConfigView />} />
      </Routes>
    </VStack>
  </HStack>
)

const SignInView = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  return (
    <Center bg='gray.800' w='100%' h='100%'>
      <VStack>
        <Button
          isLoading={loading}
          border='1px solid #ffffff77'
          colorScheme='blue'
          size='lg'
          onClick={() => {
            setLoading(true)
            popupSignIn().catch((e) => {
              setError(e.message)
              setLoading(false)
            })
          }}
        >
          SIGN IN
        </Button>
        {error ? <Text color='red.300'>{error}</Text> : null}
      </VStack>
    </Center>
  )
}

const LoadingView = () => (
  <Center w='100%' bg='gray.800' h='100%'>
    <CircularProgress color='gray' isIndeterminate />
    <Text color='white'>Loading...</Text>
  </Center>
)

const NotAdminView = () => (
  <Center w='100%' bg='gray.800' h='100%'>
    <Text color='white'>Not an admin</Text>
  </Center>
)

export const AdminDashboard = () => {
  const { user, isAdmin, loading } = useUser()
  if (!user) return <SignInView />

  if (!isAdmin) {
    if (loading) return <LoadingView />
    return <NotAdminView />
  }

  return <SignedInView />
}
