import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box, Button, Center, CircularProgress, HStack, Text,
} from '@chakra-ui/react'
import {
  BusStopAd, CityConfig, FieldMap, FileField, TextField, WithId,
} from '@loneworld/shared'
import { CITY_CONFIG_REF } from '../../backend/db'
import { useDocument } from '../../backend/hooks'
import { ListEdit } from '../forms/ListEdit'

const adImageField: FileField = {
  _type: 'file',
  accept: ['image'],
  placeholder: 'Image',
}

const adTitleField: TextField = {
  _type: 'text',
  placeholder: 'Title',
}

const adLinkField: TextField = {
  _type: 'text',
  placeholder: 'Link (optional)',
  optional: true,
}

const busStopAdField: FieldMap = {
  name: 'Bus Stop Ad',
  children: {
    image: adImageField,
    title: adTitleField,
    link: adLinkField,
  },
}

const BusStopAdPreview = ({ value }: { value: WithId<BusStopAd> }) => (
  <Center w='100%' flex={1} px={3}>
    {value.link ? (
      <a href={`https://www.youtube.com/watch?v=${value.link}`} target='_blank' rel='noreferrer'>
        <Button _hover={{ bg: 'whiteAlpha.500' }} textDecorationColor='white' variant='ghost'>
          <ExternalLinkIcon
            filter='drop-shadow(1px 1px 3px black)'
            color='gray.50'
            width={5}
            height={5}
          />
          <Text
            ml={2}
            color='gray.50'
            fontSize='xl'
            fontFamily='Gloria Hallelujah'
            textShadow='1px 1px 3px black'
          >
            Open Link
          </Text>
        </Button>
      </a>
    ) : (
      <Text color='gray.50'
        fontSize='xl'
        fontFamily='Gloria Hallelujah'
        textShadow='1px 1px 3px black'>No link</Text>
    )}
  </Center>
)

const CityConfigForm = ({ config }: { config: WithId<CityConfig> | null }) => (
  <ListEdit<BusStopAd>
    value={config?.busStopAds}
    baseStoragePath='city/config'
    ItemPreview={BusStopAdPreview}
    documentRef={CITY_CONFIG_REF}
    propertyPath='busStopAds'
    itemName='bus stop ad'
    getItemTitle={(item) => item.title}
    itemField={busStopAdField}
    expandedItemHeight={400}
    getItemBackgroundUrl={(item) => item.image.url}
    listName='Bus Stop Ads'
  />
)

export const CityConfigView = () => {
  const { data: cityConfig, isLoading, error } = useDocument(CITY_CONFIG_REF)
  let body = null
  if (!cityConfig && isLoading) {
    body = (
      <HStack pt={8}>
        <CircularProgress isIndeterminate color='gray' size={5} />
        <Text fontFamily='Gloria Hallelujah' color='white'>
          Loading city config...
        </Text>
      </HStack>
    )
  } else if (error) {
    body = (
      <Text pt={8} color='red.300'>
        {error.message}
      </Text>
    )
  } else {
    body = (
      <Box maxW='100%' w='500px'>
        <CityConfigForm config={cityConfig} />
      </Box>
    )
  }
  return (
    <Center p={2} w='100%'>
      {body}
    </Center>
  )
}
