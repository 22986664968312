import { ChakraProvider } from '@chakra-ui/react'
import { cityViews, soundViews, theatreViews } from '@loneworld/shared'
import { useIsomorphicLayoutEffect } from '@react-spring/three'
import {
  BrowserRouter, Route, Routes, useLocation,
} from 'react-router-dom'
import { setView } from '../../store'
import { AdminDashboard } from '../Admin/AdminDashboard'
import './App.css'
import { LoadingScreen } from './LoadingScreen'
import { Scene } from './Scene/Scene'
import { theme } from './theme'

const App = () => {
  const { pathname } = useLocation()

  useIsomorphicLayoutEffect(() => {
    const match = [...cityViews, ...theatreViews, ...soundViews].find(
      ({ path }) => path === pathname,
    )
    if (match) setView(match.id)
  }, [pathname])
  return (
    <>
      <Scene />
      <LoadingScreen />
    </>
  )
}

const Router = () => (
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/admin" element={<AdminDashboard/>} />
        <Route path="/admin/*" element={<AdminDashboard/>} />
        <Route path="/*" element={<App/>} />
      </Routes>
    </ChakraProvider>
  </BrowserRouter>
)

export default Router
