import { useProgress } from '@react-three/drei'
import { create } from 'zustand'

const EXPECTED_ITEMS = 39
// const EXPECTED_ITEMS = 0

if (EXPECTED_ITEMS) {
  useProgress.subscribe(({ loaded }) => {
    if (loaded >= EXPECTED_ITEMS) {
      setTimeout(() => {
        useLoading.setState({ progress: Math.min(1, loaded / EXPECTED_ITEMS), loaded: loaded >= EXPECTED_ITEMS })
      }, 500)
    } else {
      useLoading.setState({ progress: Math.min(1, loaded / EXPECTED_ITEMS), loaded: loaded >= EXPECTED_ITEMS })
    }
  })
}
export const useLoading = create(() => ({
  loaded: EXPECTED_ITEMS < 1,
  progress: EXPECTED_ITEMS ? 0 : 1,
}))
