import { getFunctions, httpsCallable } from 'firebase/functions'
import { app } from './app'

export const functions = getFunctions(app)
export const fetchPlaylist = httpsCallable(
  functions,
  'fetchPlaylist',
)

export const deleteFilesWithPrefix = httpsCallable<{
  prefix: string
}, void>(
  functions,
  'deleteFilesWithPrefix',
)
