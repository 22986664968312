import { Checkbox } from '@chakra-ui/react'
import { BooleanField } from '@loneworld/shared'
import { FC } from 'react'
import { InputProps } from '../types'

export const BooleanComponent: FC<InputProps<BooleanField>> = ({
  field,
  input: { onChange, value, ...input },
}) => {
  const { placeholder } = field
  return (
    <Checkbox
      isChecked={value}
      onChange={(e) => onChange(e.target.checked)}
      {...input}
    >
      {placeholder}
    </Checkbox>
  )
}
