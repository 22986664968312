import { a, useSpring } from '@react-spring/three'
import {
  Box, Plane, useGLTF,
} from '@react-three/drei'
import { Suspense, useEffect } from 'react'
import {
  Color, Mesh, MeshStandardMaterial, Vector3Tuple,
} from 'three'
import { BusStop } from './BusStop'
import { Record } from './ComingSoon'
import { Contact } from './Contact'
import { useCamera, useViewVisible } from './hooks'
import { Shop } from './Shop'
import { Splash } from './Splash'

const lightPositions: Array<Vector3Tuple> = [
  [2.5, 3.2, 2],
]
const neonRedColor = new Color()
neonRedColor.r = 9
neonRedColor.b = 0.0
neonRedColor.g = 0.0

const neonOrangeColor = new Color()
neonOrangeColor.r = 7
neonOrangeColor.b = 0.0
neonOrangeColor.g = 2

export const _City = () => {
  const { scene } = useGLTF('/assets/3d/LONEWORLD.glb')
  const active = useViewVisible('index')
  const { intensity } = useSpring({
    intensity: active ? 1.2 : 0,
    delay: active ? 500 : 0,
  })
  useEffect(() => {
    scene.traverse((o) => {
      const mesh = o as Mesh
      if (mesh.isMesh && !(mesh.material instanceof Array)) {
        let mat = mesh.material as MeshStandardMaterial
        if (['neon-contact', 'Sweep'].includes(mesh.name)) {
          mat.color.copy(neonOrangeColor)
          mat.roughness = 1
          mat.emissiveIntensity = 5
          mat.emissive.set(0xff4400)
        } else if (mesh.name === 'neon-theatre') {
          mat = mesh.material as MeshStandardMaterial
          mat.color.copy(neonRedColor)
          mat.roughness = 1
          mat.emissiveIntensity = 4
          mat.emissive.set(0xff0555)
        } else if (mesh.name === 'RoofGlass') {
          mat.transparent = true
          mat.opacity = 0.5
          mat.roughness = 0.005
        } else if (['Plane', 'Plane006', 'Plane003'].includes(mesh.name)) {
          mat.roughness = 0.7
          mat.metalness = 0.5
        }
      }
      return o
    })
  }, [scene])
  useCamera()
  return (
    <group rotation={[0.01, 0, 0]}>
      {/* <OrbitControls/> */}
      <primitive object={scene} />
      {lightPositions.map((position, i) => (
        // @ts-ignore
        <a.pointLight
          key={i}
          position={position}
          color={0xffffff}
          distance={15}
          intensity={intensity}
        />
      ))}
      <pointLight
        position={[2.5, 7, 4]}
        color={0xff1111}
        intensity={0.4}
        distance={15}
      />
      <Box position={[-12.5, -0.87, -6.72]} scale={[64.4, 2, 1]}>
        <meshBasicMaterial color={0x222222} />
      </Box>
      <Box position={[-12.5, -0.96, 3]} scale={[64.4, 2, 1]}>
        <meshBasicMaterial color={0x222222} />
      </Box>
      <Box position={[19.2, -0.87, 0.6]} scale={[1, 2, 13.6]}>
        <meshBasicMaterial color={0x222222} />
      </Box>
      <Box position={[-44.05, -0.87, 0.6]} scale={[1, 2, 13.6]}>
        <meshBasicMaterial color={0x222222} />
      </Box>
      <Plane
        position={[-5, 6.5, 8]}
        rotation={[Math.PI, 0, 0]}
        scale={[2, 12, 1]}
      >
        <meshBasicMaterial color={0x333333} />
      </Plane>
    </group>
  )
}

export const City = () => (
  <Suspense>
    <_City />
    <BusStop />
    <Shop />
    <Contact />
    <Record />
    <ambientLight color={0xffddaa} intensity={0.1} />
    {/* <ambientLight color={0xffddaa} intensity={1.5} /> */}
    <Splash />
    <group position={[7.75, 10.5, 7.85]}>
      <Box scale={[2.3, 2, 1]}>
        <meshBasicMaterial color={0xddccbb} />
        <pointLight position={[0, 0, -1.8]} intensity={0.5} />
      </Box>
    </group>
  </Suspense>
)
