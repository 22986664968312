import { GestureController, MobilePanGesture } from '../../types'

interface Point {
  x: number
  y: number
}

export const getMobilePanGesture = (
  gesture: MobilePanGesture,
): GestureController => ({
  subscribe: ({ controller, x }) => {
    const { horizontal, speed = 1 } = gesture
    const { max = 1, min = 0 } = controller
    let touchStart: Point | null = { x: 0, y: 0 }
    const handleTouchStart = ({ touches }: TouchEvent) => {
      if (touches.length === 1) {
        const { screenX, screenY } = touches[0]
        touchStart = { x: screenX, y: screenY }
      }
    }

    const handleTouchMove = ({ touches }: TouchEvent) => {
      requestAnimationFrame(() => {
        if (touches.length === 1) {
          const { screenX, screenY } = touches[0]
          if (!touchStart) touchStart = { x: screenX, y: screenY }
          const delta = (
            horizontal
              ? touchStart.x - screenX
              : touchStart.y - screenY) * (speed / 10000)
          x.start(Math.max(min, Math.min(max, x.get() + delta)))
        }
      })
    }
    const handleTouchEnd = () => {
      touchStart = null
    }

    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchmove', handleTouchMove, { passive: true })
    window.addEventListener('touchend', handleTouchEnd)
    return () => {
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchmove', handleTouchMove)
      window.removeEventListener('touchend', handleTouchEnd)
    }
  },
})
