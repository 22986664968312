import { GestureToController, ScrollGesture } from '../../types'

export const getScrollGesture: GestureToController<ScrollGesture> = (
  bit: ScrollGesture,
) => ({
  subscribe: ({ controller, x, throttle }) => {
    const { speed = 1 } = bit
    const { max = 1, min = 0 } = controller
    // desktop gesture handlers
    const handleWheel = (e: WheelEvent) => throttle(() => {
      // we may want to debounce this for variations in hardware (eg mousepad vs mouse)
      const diff = (e.deltaY < 0 ? -1 : 1) * speed * 0.05
      x.start(Math.max(min, Math.min(max, x.get() + diff)))
    })
    window.addEventListener('wheel', handleWheel, { passive: true })
    return () => {
      window.removeEventListener('wheel', handleWheel)
    }
  },
})
