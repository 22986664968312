import { useThree } from '@react-three/fiber'
import { FC, useEffect } from 'react'
import DoobStats from 'stats.js'

export const Stats: FC<{statLevel: number}> = ({ statLevel }) => {
  const { scene } = useThree()
  useEffect(() => {
    const stats = new DoobStats()
    stats.showPanel(statLevel)
    scene.onBeforeRender = () => {
      stats.begin()
    }
    scene.onAfterRender = () => {
      stats.end()
    }
    document.body.appendChild(stats.dom)
  }, [scene, statLevel])
  return null
}
