import {
  Center, Image, Text, VStack,
} from '@chakra-ui/react'
import { useLoading } from '../../store'

export const LoadingScreen = () => {
  const loaded = useLoading((s) => s.loaded)
  const progress = useLoading((s) => s.progress)
  return (
    <Center
      style={{
        transition: 'opacity 1000ms ease 750ms, background 1000ms ease',
        opacity: loaded ? 0 : 1,
        display: 'flex',
        background: `rgba(10,10,10,${loaded ? 0 : 1})`,
        position: 'absolute',
        top: 0,
        pointerEvents: 'none',
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <VStack>
        <Image id='loading-img' w='120px' src='/logo192.png' />
        <Text
          fontSize='4xl'
          mixBlendMode='difference'
          fontFamily='courier'
          color='white'
        >
          {(progress * 100).toFixed()}%
        </Text>
      </VStack>
    </Center>
  )
}
