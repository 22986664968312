import { useGLTF, useTexture } from '@react-three/drei'
import { FADE_IMAGE_SRC } from './consts'

export const preloadAll = () => {
  useGLTF.preload('/assets/3d/theater/play.glb')
  useGLTF.preload('/assets/3d/theater/pause.glb')
  useGLTF.preload('/assets/3d/theater/prev.glb')
  useGLTF.preload('/assets/3d/theater/skip.glb')
  useGLTF.preload('/assets/3d/theater/grid.glb')
  useGLTF.preload('/assets/3d/theater/volume.glb')
  useGLTF.preload('/assets/3d/rooms/SOUND.glb')

  useGLTF.preload('/assets/3d/LONEWORLD.glb')
  useTexture.preload(FADE_IMAGE_SRC)
  useGLTF.preload('/assets/3d/rooms/shop/door.glb')
  useGLTF.preload('/assets/3d/theater/theatertest.glb')
  useGLTF.preload('/assets/3d/record.glb')
}
