import {
  Center, Image, Spinner, Text,
} from '@chakra-ui/react'
import { FileField, FileValue } from '@loneworld/shared'
import {
  ChangeEventHandler, forwardRef,
  useCallback,
  useEffect,
  useMemo, useRef, useState,
} from 'react'
import { useInputImperativeHandle } from './hooks'
import pdfIcon from './icons/pdf.svg'
import { InputProps, InputRef } from './types'

const generateAccept = (field: FileField) => {
  if (!field.accept) return undefined
  const accept = []
  if (field.accept.includes('image')) {
    accept.push(
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/svg',
      'image/gif',
    )
  }
  if (field.accept.includes('pdf')) accept.push('application/pdf')
  return accept.join(',')
}

export const FileView = ({ value, readOnly }: { value?: FileValue, readOnly?: boolean }) => {
  const { url, dataUrl } = value || {}
  const [loaded, setLoaded] = useState(false)
  const isImage = useMemo(() => value?.type?.indexOf('image') === 0, [value])
  if (!value) {
    return (
      <Center bg='whiteAlpha.600' width='100px' height='100px' borderRadius='full'>
      <Text fontSize='md' fontFamily='Gloria Hallelujah' textAlign='center' color='gray.600'>
        <i>UPLOAD AN IMAGE</i>
      </Text>
      </Center>

    )
  }
  return (
    <Center w='100%' height='100%'>
      {isImage ? (
        <Image
          width='100%'
          height='100%'
          borderRadius={6}
          opacity={loaded ? 1 : 0}
          transition='opacity 333ms ease'
          objectFit='cover'
          onLoad={() => setLoaded(true)}
          src={dataUrl || url}
        />
      ) : (
        <Image src={pdfIcon} />
      )}
      {isImage && !loaded ? (
        <Spinner position='absolute' color='white' />
      ) : null}
      {
        readOnly ? null : (
          <Center
            position='absolute'
            top={0}
            left={0}
            right={0}
            bottom={0}
            fontFamily='Gloria Hallelujah'
            color='whiteAlpha.700'
            fontSize='sm'
            fontWeight='bold'
          >
            CLICK TO EDIT
          </Center>
        )
      }
    </Center>
  )
}
export const FileComponent = forwardRef<InputRef, InputProps<FileField>>(
  ({
    input: {
      value, onChange, onBlur,
    }, field,
  }, ref) => {
    useInputImperativeHandle(ref, () => {
      inputRef.current?.click()
    })
    const inputRef = useRef<HTMLInputElement>(null)
    const [reader] = useState(new FileReader())
    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        const file = e.target.files?.[0]
        if (!file) return
        reader.addEventListener(
          'load',
          async () => {
            // convert image file to base64 string
            if (typeof reader.result !== 'string') {
              console.error('reader result isnt a data url')
              return
            }
            onChange({ ...value, dataUrl: reader.result, type: file.type })
          },
          false,
        )
        reader.readAsDataURL(file)
      },
      [onChange, value, reader],
    )
    // const inputRef = useInputImperativeHandle(ref)

    useEffect(() => {
      if (!inputRef.current) return
      inputRef.current.addEventListener('cancel', () => {
        console.log('cancelled')
        onBlur()
      })
    }, [onBlur])

    return (
      <Center
        height={150}
        cursor='pointer'
        onClick={() => inputRef.current?.click()}
        width='100%'
      >
        <Center
          maxW='150px'
          width='100%'
          height='100%'
          position='relative'
          overflow='hidden'
        >
          <FileView value={value} />
          <input
            onChange={handleChange}
            ref={inputRef}
            accept={generateAccept(field)}
            type='file'
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              height: 0.1,
              width: 0.1,
              opacity: 0,
            }}
          />
        </Center>
      </Center>
    )
  },
)
