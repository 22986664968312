import { useToast } from '@chakra-ui/react'
import { WithId } from '@loneworld/shared'
import { FirebaseError } from 'firebase/app'
import {
  DocumentData,
  DocumentReference,
  onSnapshot,
  Query,
} from 'firebase/firestore'
import { getDownloadURL, getMetadata, ref } from 'firebase/storage'
import { useEffect, useRef, useState } from 'react'
import { storage } from './storage'

export const useDocument = <T extends DocumentData>(
  docRef?: DocumentReference<T> | null,
) => {
  const [data, setData] = useState<WithId<T> | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<FirebaseError | null>(null)
  useEffect(() => {
    if (!docRef) {
      setIsLoading(false)
      return () => {}
    }
    setIsLoading(true)
    return onSnapshot(docRef, {
      next: (snap) => {
        setIsLoading(false)
        const fetched = snap.data()
        if (!fetched) setData(null)
        else setData({ ...fetched, _id: snap.id })
      },
      error: (err) => {
        console.log(err)
        setError(err)
        setIsLoading(false)
      },
    })
  }, [docRef])

  return { isLoading, data, error }
}

export const useQuery = <T extends DocumentData>(
  query: Query<T> | null,
  refetchWithoutClear?: boolean,
) => {
  const [data, setData] = useState<Array<WithId<T>>>([])
  const [isLoading, setIsLoading] = useState(!!query)
  const [error, setError] = useState<FirebaseError | null>(null)
  const fetchedOn = useRef<number | null>(null)

  useEffect(() => {
    if (!query) return () => {}
    if (!fetchedOn.current && !refetchWithoutClear) {
      setIsLoading(true)
      setData([])
    }
    fetchedOn.current = Date.now()
    return onSnapshot(query, {
      next: (snap) => {
        setIsLoading(false)
        setData(
          snap.docs.map((doc) => ({ _id: doc.id, ...doc.data() } as WithId<T>)),
        )
      },
      error: (err) => {
        console.log(err)
        setError(err)
      },
    })
  }, [query, refetchWithoutClear])

  return { isLoading, data, error }
}

export const getObjectUrl = async (storagePath: string | null) => {
  if (!storagePath) return null
  const storageRef = ref(storage, storagePath)
  return getDownloadURL(storageRef)
}

export const getObjectMetadata = async (storagePath: string | null) => {
  if (!storagePath) return null
  const storageRef = ref(storage, storagePath)
  return getMetadata(storageRef)
}

export const useObjectUrl = (storagePath: string | null) => {
  const [url, setUrl] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  useEffect(() => {
    if (!storagePath) return
    setLoading(true)
    getObjectUrl(storagePath)
      .then((fetchedUrl) => {
        setUrl(fetchedUrl)
        setLoading(false)
      })
      .catch((err) => {
        if (err.code !== 'storage/object-not-found') {
          console.error(err)
          toast({ status: 'error', title: 'Error fetching image' })
        }
        setUrl(null)
        setLoading(false)
      })
  }, [storagePath, toast])

  return { url, loading }
}
