import {
  CITY, CityConfig, CONFIG, DBUser, THEATER, TheaterConfig, USERS,
} from '@loneworld/shared'
import {
  collection, doc, DocumentReference, getFirestore,
} from 'firebase/firestore'
import { app } from './app'

export const db = getFirestore(app)
export const CITY_REF = collection(db, CITY)
export const CITY_CONFIG_REF = doc(CITY_REF, CONFIG) as DocumentReference<CityConfig>

export const THEATER_REF = collection(db, THEATER)
export const THEATER_CONFIG_REF = doc(THEATER_REF, CONFIG) as DocumentReference<TheaterConfig>

export const getUserDocRef = (uid: string) => doc(db, USERS, uid) as DocumentReference<DBUser>
