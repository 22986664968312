import { Poster } from '@loneworld/shared'
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { YouTubePlayer } from 'react-youtube'
import { fetchPlaylist } from '../../../../../backend/functions'
import { PlaylistItemsResponse } from './types'

const usePlaylist = () => {
  const [data, setData] = useState<PlaylistItemsResponse | null>(null)
  const [items, setItems] = useState<PlaylistItemsResponse['items'] | null>(
    null,
  )
  const [nextPageTokens, setNextPageTokens] = useState<Array<string>>([])
  const [isFetching, setIsFetching] = useState(false)
  useEffect(() => {
    const reqData = { playlistId: 'PLSfw6g9ZG54RSH7cUip4xBiXnbOQHGZhE', maxResults: 25 } as Record<
      string,
      string | number
    >
    if (nextPageTokens.length) {
      reqData.pageToken = nextPageTokens[nextPageTokens.length - 1]
    }
    setItems(null)
    setIsFetching(true)
    fetchPlaylist(reqData).then((res) => {
      const fetched = res.data as PlaylistItemsResponse
      setData(fetched)
      setItems(fetched?.items || null)
      setIsFetching(false)
    })
  }, [nextPageTokens])

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null)

  const hovered = useMemo(
    () => (hoveredIndex !== null ? items?.[hoveredIndex] : undefined),
    [items, hoveredIndex],
  )
  const selected = useMemo(
    () => (selectedIndex !== null ? items?.[selectedIndex] : undefined),
    [items, selectedIndex],
  )
  const { numPages, currentPage } = useMemo(() => {
    const num = Math.ceil((data?.pageInfo?.totalResults || 0) / 25)
    return {
      numPages: num,
      currentPage: (nextPageTokens.length % num) + 1,
    }
  }, [data, nextPageTokens])

  const goNext = useCallback(() => {
    if (!data) return
    setNextPageTokens((p) => [...p, data.nextPageToken])
  }, [data])
  const goPrev = useCallback(() => {
    if (!nextPageTokens.length) return
    setNextPageTokens((prev) => prev.slice(0, prev.length - 1))
  }, [nextPageTokens])

  const goNextVideo = useCallback(() => {
    if (!items || selectedIndex === null) return
    if (selectedIndex < items.length - 1) setSelectedIndex(selectedIndex + 1)
    else {
      goNext()
      setSelectedIndex(0)
    }
  }, [selectedIndex, items, goNext])

  const goPrevVideo = useCallback(() => {
    if (!items || selectedIndex === null) return
    if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1)
    else {
      goPrev()
      setSelectedIndex(24)
    }
  }, [selectedIndex, items, goPrev])

  const playerVisible = useMemo(
    () => selectedVideoId !== null || selectedIndex !== null,
    [selectedIndex, selectedVideoId],
  )
  return useMemo(
    () => ({
      data,
      items,
      goNext,
      goPrev,
      isFetching,
      selected,
      hovered,
      numPages,
      selectedIndex,
      playerVisible,
      goNextVideo,
      selectedVideoId,
      goPrevVideo,
      currentPage,
      onHover: (index: number | null) => setHoveredIndex(index),
      onSelect: (index: number | null) => {
        setSelectedVideoId(null)
        setSelectedIndex(index)
      },
      onSelectId: (videoId: string) => {
        setSelectedIndex(null)
        setSelectedVideoId(videoId)
      },
      pageIndex: nextPageTokens.length,
    }),
    [
      data,
      goNext,
      goPrev,
      items,
      selectedVideoId,
      playerVisible,
      goNextVideo,
      selectedIndex,
      goPrevVideo,
      isFetching,
      nextPageTokens,
      hovered,
      selected,
      numPages,
      currentPage,
    ],
  )
}

export const usePlayer = () => {
  const [player, setPlayer] = useState<YouTubePlayer | null>(null)
  const [playerState, setPlayerState] = useState<any | null>(null)
  const [muted, setMuted] = useState(false)
  return {
    player,
    playerState,
    onPlayerReady: (p: YouTubePlayer) => {
      setPlayer(p)
      p.unMute()
    },
    onPlayerState: (state: any) => setPlayerState(state),
    onVolumePress: (isMuted: boolean) => setMuted(isMuted),
    muted,
  }
}

export const useTheater = () => {
  const playlist = usePlaylist()
  const player = usePlayer()
  return useMemo(() => ({ playlist, player }), [playlist, player])
}

const sleep = (ms: number) => new Promise((resolve) => { setTimeout(resolve, ms) })

export const usePosterUrl = (loadMsOffset: number, posters: Poster[] | undefined) => {
  const [index, setIndex] = useState(0)
  const interval = useRef<ReturnType<typeof setInterval>>()
  const mounted = useRef<boolean>(false)
  useEffect(() => {
    const initialize = async () => {
      if (!mounted.current) {
        await sleep(loadMsOffset)
        mounted.current = true
      }
      if (interval.current) clearInterval(interval.current)
      interval.current = setInterval(() => {
        setIndex((prev) => (prev + 1) % (posters?.length || 1))
      }, 8000)
      return () => clearInterval(interval.current)
    }
    initialize()
  }, [posters, loadMsOffset])

  const onClick = useCallback(() => {
    if (interval.current) clearInterval(interval.current)
    interval.current = setInterval(() => {
      setIndex((prev) => (prev + 1) % (posters?.length || 1))
    }, 8000)
    setIndex((prev) => (prev + 1) % (posters?.length || 1))
  }, [posters])
  const poster = useMemo(() => posters?.[index], [posters, index])

  return useMemo(() => ({
    index, onClick, poster,
  }), [index, onClick, poster])
}
