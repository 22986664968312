import { CityView, cityViews, TheatreView } from '@loneworld/shared'
import { create } from 'zustand'

export type AppState = {entered: boolean, cameraShouldFlyIn: boolean, view: CityView | TheatreView | 'sound'}
export const useApp = create<AppState>(() => ({
  entered: false,
  view: 'index',
  cameraShouldFlyIn: cityViews.map(({ path }) => path).includes(window.location.pathname),
}))

export const setView = (viewName: CityView | TheatreView | 'sound') => useApp.setState({ view: viewName })
