import {
  Box, Button, Center, Image, Text,
} from '@chakra-ui/react'
import { cityViews } from '@loneworld/shared'
import { easings } from '@react-spring/three'
import { a, useSpring } from '@react-spring/web'
import { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useApp, useScreen } from '../store'
import { locations } from './App/Scene/layers/City/locations'

const locationKeys = Object.keys(locations)
const getCityDots = (width: number, offset: number) => {
  let currPoint = 0
  const dots: Array<number> = []
  const paths: Array<[number, number]> = []
  locationKeys.forEach((locationKey, i) => {
    dots.push(currPoint)
    const newPoint = currPoint + 1 / (locationKeys.length - 1)
    if (i < locationKeys.length - 1) {
      paths.push([currPoint + 0.017, newPoint - 0.017])
    }
    currPoint = newPoint
  })
  return {
    dots: dots.map((d) => d * width + offset),
    paths: paths.map(([p1, p2]) => [p1 * width + offset, p2 * width + offset]),
  }
}

const cityIcons = ['home', 'door', 'contact', 'distance', 'shopping_cart_bl']

const { dots, paths } = getCityDots(0.6, 0.2)
const theatreDot = 0.1
const soundDot = 0.9

const NavButton = ({
  src,
  left,
  size,
  active,
  path,
  text,
}: {
  src: string
  left: number
  size: number
  path: string
  active: boolean
  text: string
}) => {
  const navigate = useNavigate()
  const [hovered, setHovered] = useState(false)
  const opacity = useMemo(() => {
    if (active) return 0.7
    return hovered ? 0.4 : 0
  }, [hovered, active])
  return (
    <Button
      pos='absolute'
      top='0'
      variant='unstyled'
      left={`${left}px`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => navigate(path)}
      display='flex'
      alignItems='center'
      justifyContent='center'
      height={`${size}px`}
      width={`${size}px`}
    >
      <Text
        color='white'
        style={{
          opacity,
          transition: `opacity 500ms ease ${active ? 1000 : 0}ms`,
        }}
        fontSize='xs'
        fontFamily='Inter'
        position='absolute'
        whiteSpace='nowrap'
        top={`-${size / 8}px`}
      >
        {text}
      </Text>
      <Image
        position='relative'
        bottom={`-${size / 2}px`}
        style={{
          opacity: active ? 0.8 : 0.5,
          transition: `opacity 500ms ease ${active ? 1000 : 0}ms`,
        }}
        h={`${size / 2.5}px`}
        filter='invert(100%)'
        src={src}
      />
    </Button>
  )
}

export const NavBar = () => {
  const view = useApp((s) => s.view)
  const width = useScreen((s) => s.width)
  const barWidth = useMemo(() => Math.min(width, 600), [width])
  const { selectedCityIdx, pos } = useMemo(() => {
    if (view === 'theatre') {
      return { pos: theatreDot * 100, selectedCityIdx: null }
    }
    if (view === 'sound') return { pos: dots[1] * 100, selectedCityIdx: 1 }
    const cityIdx = cityViews.map(({ id }) => id).indexOf(view)
    if (cityIdx !== -1) {
      return { pos: dots[cityIdx] * 100, selectedCityIdx: cityIdx }
    }
    return { pos: dots[0] * 100, selectedCityIdx: 0 }
  }, [view])
  const [{ r }, api] = useSpring(() => ({ r: 1.5 }))
  const { x } = useSpring({
    x: pos,
    delay: 500,
    config: { duration: 500, easing: easings.easeInOutCubic },
    onProps: () => api.start({ r: 0.3 }),
    onResolve: () => api.start({ r: 1.5, delay: 500 }),
  })

  const shouldFlyIn = useApp((s) => s.cameraShouldFlyIn)
  return (
    <Center
      bottom={`${barWidth / 20}px`}
      pos='absolute'
      w='100%'
      opacity={shouldFlyIn ? 0 : 1}
      pointerEvents={shouldFlyIn ? 'none' : 'auto'}
      transition='opacity 500ms'
    >
      <Box
        style={{
          width: `${barWidth}px`,
        }}
        position='relative'
      >
        <svg style={{ width: '100%' }} viewBox='0 0 100 10'>
          {paths.map(([p1, p2], i) => (
            <path
              key={`path-${i}`}
              strokeLinecap='round'
              d={`M ${p1 * 100} 5 L ${p2 * 100} 5`}
              stroke='#ffffff88'
              strokeWidth={0.6}
            />
          ))}
          {[...dots, theatreDot, soundDot].map((c, i) => (
            <circle
              key={`circle-${i}`}
              cx={c * 100}
              cy={5}
              r={1}
              fill='#ffffff77'
            />
          ))}
          <a.circle
            cx={x}
            cy={5}
            r={r}
            stroke='#ffffffcc'
            strokeWidth={0.6}
            fill='none'
          />
        </svg>
        <NavButton
          text='THEATRE'
          active={view === 'theatre'}
          path='/theatre'
          left={0.05 * barWidth}
          src='/assets/svg/theater.svg'
          size={barWidth / 10}
        />
        {dots.map((d, i) => (
          <NavButton
            text={cityViews[i].name.toUpperCase()}
            key={cityIcons[i]}
            path={cityViews[i].path}
            active={i === selectedCityIdx}
            left={(d - 0.05) * barWidth}
            size={barWidth / 10}
            src={`/assets/svg/${cityIcons[i]}.svg`}
          />
        ))}
        <Link to={'https://submit.whyloneworld.com/'}>
        <NavButton
          text='SUBMIT'
          active={view === 'sound'}
          path='https://submit.whyloneworld.com'
          left={0.85 * barWidth}
          src='/assets/svg/ticket.svg'
          size={barWidth / 10}
        />
        </Link>
      </Box>
    </Center>
  )
}
