import { Vector3Tuple } from 'three'

export const meshNameToVideoIndex: Record<string, number> = {
  Cube009: 0,
  Cube008: 1,
  Cube010: 2,
}
// export const videoIds = Object.values(meshNameToVideoId)

export const spotlightPositions: Array<Vector3Tuple> = [
  [-12, 8, 7.7],
  [-12.9, 7, 1.2],
  [-13.5, 6.2, -6],
]
