import { TheaterConfig } from '@loneworld/shared'
import { a, useSpring } from '@react-spring/three'
import { OrbitControls, useGLTF } from '@react-three/drei'
import { SpotLightProps, useThree } from '@react-three/fiber'
import {
  Suspense, useContext, useEffect, useMemo, useRef, useState,
} from 'react'
import YouTube from 'react-youtube'
import { Mesh, Object3D, Vector3Tuple } from 'three'
import { THEATER_CONFIG_REF } from '../../../../../backend/db'
import { useDocument } from '../../../../../backend/hooks'
import { Layer, LayerComponent } from '../../../../SceneViewer/types'
import { meshNameToVideoIndex, spotlightPositions } from './constants'
import { TheaterContext, TheaterProvider } from './context'
import { Links } from './Links'
import { MoviePosters } from './MoviePosters'
import { Playlist } from './Playlist'
import { Screen } from './Screen'

const TheaterRoom = ({
  onHoverPoster,
  onBlurPoster,
}: {
  onHoverPoster: (index: number) => void
  onBlurPoster: () => void
}) => {
  const {
    playlist: { onSelectId },
  } = useContext(TheaterContext)
  const { domElement } = useThree((s) => s.gl)
  const { nodes } = useGLTF('/assets/3d/theater/theatre-02-25-2024.glb') as unknown as {
    nodes: Record<string, Object3D>
  }
  const meshes = useMemo(
    () => Object.entries(nodes).reduce((acc, [id, obj]) => {
      const asMesh = obj as Mesh
      if (asMesh.isMesh) {
        return { ...acc, [id]: obj as Mesh }
      }
      return acc
    }, {} as Record<string, Mesh>),
    [nodes],
  )
  const { data: theaterConfig, isLoading, error } = useDocument<TheaterConfig>(THEATER_CONFIG_REF)

  const withListeners = useMemo(
    () => Object.entries(meshes).reduce((acc, [id, mesh]) => {
      const frameIndex = meshNameToVideoIndex[id]
      const isPoster = Object.keys(meshNameToVideoIndex).includes(id)
      if (isPoster) {
        return [
          ...acc,
          {
            mesh,
            onPointerEnter: () => {
              domElement.style.cursor = 'pointer'
              onHoverPoster(frameIndex)
            },
            onPointerLeave: () => {
              domElement.style.cursor = 'default'
              onBlurPoster()
            },
          },
        ]
      }
      return [...acc, { mesh }]
    }, [] as Array<{ mesh: Mesh; onClick?: () => void; onPointerEnter?: () => void; onPointerLeave?: () => void }>),
    [meshes, domElement, onBlurPoster, onHoverPoster],
  )
  return (
    <>
      <MoviePosters onPosterBlur={onBlurPoster} onPosterHover={onHoverPoster} theaterConfig={theaterConfig}/>
      {withListeners.map(({ mesh, ...listeners }) => (
        <mesh
          key={mesh.name}
          geometry={mesh.geometry}
          material={mesh.material}
          position={mesh.position}
          castShadow
          receiveShadow
          scale={mesh.scale}
          rotation={mesh.rotation}
          {...listeners}
        />
      ))}
    </>
  )
}

const AnimatedSpotLight = ({ penumbra: p, intensity: i, ...props }: SpotLightProps) => {
  const { penumbra, intensity } = useSpring({ penumbra: p, intensity: i })
  // const light = useRef<Object3D>(null) as any
  // useHelper(light as any, SpotLightHelper, 'cyan')
  return (
    <>
      {/* @ts-ignore */}
      <a.spotLight
        // ref={light}
        penumbra={penumbra}
        intensity={intensity}
        castShadow
        receiveShadow
        shadow-camera-far={20}
        shadow-camera-near={2}
        shadow-mapSize-width={64}
        shadow-mapSize-height={64}
        shadow-bias={-0.001}
        {...props}
      />
    </>
  )
}

const createObjectAtPoint = (p: Vector3Tuple) => {
  const obj = new Object3D()
  obj.position.set(...p)
  return obj
}
export const Theater: LayerComponent = () => {
  const ref = useRef<YouTube>(null)
  const cam = useThree((s) => s.camera)
  const [hoveredPosterIdx, setHoveredPosterIdx] = useState<number | null>(null)
  const targets = useMemo(() => spotlightPositions.map((pos) => createObjectAtPoint(pos)), [])

  useEffect(() => {
    cam.position.set(0, 8.5, 8.01)
    cam.lookAt(0, 8.5, 8)
  }, [cam])
  return (
    <TheaterProvider>
      <OrbitControls
        makeDefault
        minAzimuthAngle={-Math.PI / 2}
        enablePan={false}
        enableZoom={false}
        maxAzimuthAngle={Math.PI / 2}
        rotateSpeed={-1}
        target={[0, 8.5, 8]}
        maxDistance={0.05}
      />
      <group position={[0, 4, 0]}>
        <Suspense>
          <Links />
          <pointLight intensity={1.2} position={[0, 6, 0]} />
          {spotlightPositions.map((pos, index) => (
            <AnimatedSpotLight
              position={[pos[0] + 9, pos[1] + 7, pos[2] - 7]}
              angle={0.28}
              key={index}
              // distance={12}
              // decay={0.1}
              color={0xffcc88}
              target={targets[index]}
              penumbra={index === hoveredPosterIdx ? 0.08 : 0.1}
              intensity={index === hoveredPosterIdx ? 1.2 : 0.8}
            />
          ))}

          {targets.map((t, i) => (
            <primitive key={`spotlight_target_${i}`} object={t} />
          ))}
          <TheaterRoom onBlurPoster={() => setHoveredPosterIdx(null)} onHoverPoster={setHoveredPosterIdx} />
          <Playlist />
          <Screen ref={ref} />
        </Suspense>
      </group>
    </TheaterProvider>
  )
}

export const theaterLayer: Layer = {
  Component: Theater,
}
