import {
  Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure,
} from '@chakra-ui/react'
import { SelectField } from '@loneworld/shared'
import { FC, useMemo } from 'react'
import { InputProps } from '../types'

export const SelectComponent: FC<InputProps<SelectField>> = ({ field, input, meta }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    placeholder, label, options, renderOption,
  } = field
  const { onChange, value } = input
  const selected = useMemo(() => value && options.find((o) => o.id === value), [value, options])
  return (
    <Flex flexFlow='column' align='center' w='100%'>
      {label ? <Text>{label}</Text> : null}
      <Menu strategy='fixed' isOpen={isOpen} onClose={onClose}>
        <MenuButton
          _hover={{ background: 'whiteAlpha.400' }}
          style={{
            boxShadow: 'inner 0 0 3px black',
          }}
          // height={14}
          w='100%'
          // style={{padding: '0'}}
          onClick={(e) => {
            e.stopPropagation()
            onOpen()
          }}
          variant='unstyled'
          as={Button}
          color='gray.200'
          fontWeight={500}
        >
          {selected ? renderOption(selected) : placeholder}
        </MenuButton>
          <MenuList zIndex={2} boxShadow='md' p={1} bg='gray.400'>
            {options.map((o) => (
              <MenuItem _hover={{ bg: 'transparent' }} p={0} mt={1} bg='transparent' fontSize='md' color='white' fontFamily='Encode Sans' key={o.id} onClick={() => onChange(o.id)}>
                {renderOption(o)}
              </MenuItem>
            ))}
          </MenuList>
      </Menu>
    </Flex>
  )
}
