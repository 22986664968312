import { Button, Image } from '@chakra-ui/react'
import { CSSProperties } from 'react'

export const ImageButton = ({
  alt,
  url,
  height,
  width,
  style,
  onClick,
}: {
  url: string
  alt: string
  height: number
  width: number
  style?: CSSProperties
  onClick: () => void
}) => (
  <Button
    variant='unstyled'
    width={`${width}px`}
    userSelect='none'
    height={`${height}px`}
    onClick={onClick}
    style={style}
  >
    <Image src={url} alt={alt} w='100%' objectFit='contain' h='100%' />
  </Button>
)
