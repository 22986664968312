import { useToast } from '@chakra-ui/react'
import { useCallback, useMemo, useState } from 'react'

export const useWithLoading = <Args extends Array<any> = [], Result = void>(
  func?: (...args: Args) => Promise<Result> | Result,
) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [result, setResult] = useState<Result | null>(null)
  const toast = useToast()
  const call = useCallback(
    async (...args: Args) => {
      if (!func) return
      setLoading(true)
      try {
        const res = await func(...args)
        setResult(res)
      } catch (e: any) {
        setError(e)
        toast({
          title: 'Error',
          description: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      } finally {
        setLoading(false)
      }
    },
    [func, toast],
  )

  return useMemo(() => ({
    loading,
    error,
    result,
    call,
  }), [loading, error, result, call])
}
