// https://codepen.io/kenkozma17/pen/gOLgXJz?editors=1010
import { useDetectGPU } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { forwardRef, useMemo } from 'react'
import {
  CircleGeometry, RepeatWrapping, TextureLoader, Vector3,
} from 'three'
import { Water as ThreeWater } from 'three-stdlib'

export const Water = forwardRef(
  (
    {
      fog,
      alpha = 1.0,
      waterColor = '#151e1f',
    }: { fog?: boolean; alpha?: number; waterColor?: string },
    ref,
  ) => {
    // const waterGeometry = new THREE.PlaneGeometry(300, 300);
    // const waterGeometry = new THREE.PlaneGeometry(10000, 10000);
    const gl = useDetectGPU()
    const water = useMemo(() => {
      const waterGeometry = new CircleGeometry(80, 32)
      let textureSize = 512
      let normalMap = 'waternormals_512'
      if (gl.tier > 2) {
        textureSize = 1024
        normalMap = 'waternormals'
      }
      // } else if (gl.tier > 1) {
      //   textureSize = 512
      //   normalMap = 'waternormals_512'
      // }
      const w = new ThreeWater(waterGeometry, {
        textureWidth: textureSize,
        // textureWidth: 1024,
        // textureHeight: 1024,
        textureHeight: textureSize,
        /* eslint-disable no-param-reassign */
        waterNormals: new TextureLoader().load(
          `/assets/3d/textures/${normalMap}.jpg`,
          (texture) => {
            texture.wrapS = RepeatWrapping
            texture.wrapT = RepeatWrapping
          },
        ),
        /* eslint-enable no-param-reassign */
        alpha,
        sunDirection: new Vector3(1, 1, 0),
        // sunColor: 0xff5543,
        sunColor: 0x552211,
        // sunColor: 0x000000,
        // waterColor: 0x001e0f,
        waterColor,
        // waterColor: 0x005f7f,
        // distortionScale: 3.7,
        distortionScale: 3.7,
        fog,
      })
      w.rotation.x = -Math.PI / 2
      return w
    }, [])

    useFrame((_, dT) => {
      water.material.uniforms.time.value += dT / 10
    })
    return <primitive ref={ref} object={water} />
  },
)
