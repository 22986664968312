import { Sky, Stars, useDetectGPU } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useRef } from 'react'
import { Group } from 'three'
import { Water } from './Water'

export const Environment = () => {
  const g = useRef<Group>(null)
  const { tier } = useDetectGPU()
  useFrame((_, dT) => {
    if (!g.current) return
    g.current.rotation.y += dT * 0.005
    g.current.rotation.x += dT * 0.0021
  })
  return (
    <>
      {tier > 1 ? <Water /> : null}
      <Sky
        mieCoefficient={0.012}
        mieDirectionalG={0.375}
        sunPosition={[-2, 0.005, 5]}
        azimuth={Math.PI * 1.5}
        turbidity={14}
        rayleigh={3.8}
      />
      <group ref={g}>
        <Stars />
      </group>
    </>
  )
}
