import { Collapse } from '@chakra-ui/react'
import { get } from 'nested-property'
import React, { PropsWithChildren } from 'react'
import { FormSpy } from 'react-final-form'

const ConditionBody = ({ visible, children }: PropsWithChildren<{ visible: boolean }>) => (
  <Collapse style={{ width: '100%' }} in={visible}>
    {children}
  </Collapse>
)
export const Condition: React.FC<PropsWithChildren<{
  basePath: string
  condition: (v?: any) => boolean
}>> = ({ basePath, condition, children }) => (
  <FormSpy subscription={{ values: true }}>
    {({ values }) => {
      const bp = basePath
      const value = bp ? get(values, bp.replace(/\[/g, '.').replace(/\]/, '')) : values
      return (
        <ConditionBody visible={condition(value)}>
          {children}
        </ConditionBody>
      )
    }}
  </FormSpy>
)
