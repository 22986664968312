import { createContext, PropsWithChildren } from 'react'
import { useTheater } from './hooks'

export const TheaterContext = createContext<ReturnType<typeof useTheater>>({
  playlist: {
    data: null,
    currentPage: 0,
    numPages: 0,
    items: null,
    goNext: () => {},
    goPrev: () => {},
    goNextVideo: () => {},
    goPrevVideo: () => {},
    selectedIndex: null,
    isFetching: false,
    onSelect: () => {},
    onHover: () => {},
    onSelectId: () => {},
    pageIndex: 0,
    selectedVideoId: null,
    hovered: undefined,
    playerVisible: false,
    selected: undefined,
  },
  player: {
    onVolumePress: () => {},
    muted: false,
    onPlayerReady: () => {},
    onPlayerState: () => {},
    playerState: null,
    player: null,
  },
})

export const TheaterProvider = ({ children }: PropsWithChildren<{}>) => (
  <TheaterContext.Provider value={useTheater()}>
    {children}
  </TheaterContext.Provider>
)
