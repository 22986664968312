import { easings, SpringConfig, useSpring } from '@react-spring/core'
import { useThree } from '@react-three/fiber'
import { useCallback, useState } from 'react'
import { Vector3Tuple } from 'three'
import { getHelpers, setRotationFromTarget } from './utils'

export const useCameraGoTo = (init?: {
  position?: Vector3Tuple
  lookAt?: Vector3Tuple
}) => {
  const camera = useThree((s) => s.camera)
  const [helpers] = useState(getHelpers())
  const [, api] = useSpring<{ position: Vector3Tuple; t: number }>(() => ({
    to: {
      position: init?.position || camera.position.toArray(),
      t: 0,
    },
    config: { duration: 1000, easing: easings.easeInOutQuad },
    onChange: ({ value: { position, t } }) => {
      // update the camera
      if (t !== undefined) {
        camera.quaternion.slerpQuaternions(helpers.fromQ, helpers.toQ, t)
      }
      if (position) {
        camera.position.set(...(position as Vector3Tuple))
      }
      camera.updateMatrixWorld()
    },
  }))

  return useCallback(
    async ({
      position,
      lookAt,
      instant,
      rotation,
      config,
      delay,
    }: {
      position?: Vector3Tuple
      lookAt?: Vector3Tuple
      rotation?: Vector3Tuple
      config?: SpringConfig
      delay?: number
      instant?: boolean
    }): Promise<void> => new Promise((resolve) => {
      const currPos = camera.position.toArray()
      const next: Partial<{ position: Vector3Tuple; t: number }> = {}
      if (position) {
        next.position = position
      }
      if (lookAt) {
        // if (orbitControls) orbitControls.target.set(...lookAt);
        helpers.fromQ.setFromEuler(camera.rotation)
        next.t = 1
        setRotationFromTarget(position || currPos, lookAt, helpers)
      } else if (rotation) {
        // helpers.toQ.set(...rotation, 1)
        next.t = 1
      }
      if (instant) {
        api.set(next)
        resolve()
      } else {
        api.set({ position: currPos, t: 0 })
        api.start({
          position: position || currPos,
          t: 1,
          config,
          delay,
          onRest: () => { resolve() },
          onChange: ({ value: { position: p, t } }) => {
            // update the camera
            if (t !== undefined) {
              camera.quaternion.slerpQuaternions(helpers.fromQ, helpers.toQ, t)
            }
            if (p) {
              camera.position.set(...(p as Vector3Tuple))
            }
            camera.updateMatrixWorld()
          },
        })
      }
    }),
    [camera, api, helpers],
  )
}
