import { CityView, cityViews } from '@loneworld/shared'
import { easings } from '@react-spring/core'
import { useThree } from '@react-three/fiber'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useCameraGoTo } from '../../../../../hooks/camera/useCameraGoTo'
import { useGesture } from '../../../../../hooks/gestures'
import {
  GestureConfig,
  ScrollSnapGesture,
  SwipeGesture,
} from '../../../../../hooks/gestures/types'
import { useApp } from '../../../../../store'
import { index, locations } from './locations'

const targets = [0, 0.36, 0.545, 0.695, 1]
const toTarget: Record<CityView, number> = {
  index: targets[0],
  'coming-soon': targets[1],
  contact: targets[2],
  busstop: targets[3],
  shop: targets[4],
}

const mobileGesture: SwipeGesture = {
  _type: 'SWIPE_GESTURE',
  horizontal: true,
  targets,
}
const desktopGesture: ScrollSnapGesture = {
  _type: 'SCROLL_SNAP_GESTURE',
  targets,
}

export const useCamera = () => {
  const camera = useThree((s) => s.camera)
  const shouldFlyIn = useApp((s) => s.cameraShouldFlyIn)
  const goTo = useCameraGoTo()
  const view = useApp((s) => s.view)
  const navigate = useNavigate()
  const [initialView] = useState(view)
  const onSpringChange = useCallback(
    (pos: number) => {
      camera.position.setX(2.6 - 46 * pos)
      camera.position.setY(index.position[1])
      camera.position.setZ(
        pos < 0.7 ? index.position[2] : index.position[2] + 21 * (pos - 0.7),
      )
      camera.rotation.set(
        Math.PI,
        pos < 0.88 ? 0 : -(Math.PI * 2) * (pos - 0.88),
        Math.PI,
      )
    },
    [camera],
  )

  const onChangeView = useCallback((pos: number) => {
    if (view === 'theatre') return
    if (view === 'sound') return
    if (pos < 0.25 && view !== 'index') {
      navigate('/')
    } else if (pos < 0.45) {
      navigate('/coming-soon')
    } else if (pos < 0.65) {
      navigate('/contact')
    } else if (pos > 0.94) {
      navigate('/shop')
    } else if (pos > 0.65) {
      navigate('/busstop')
    }
  }, [view, navigate])
  const gestureController = useMemo<GestureConfig>(
    () => ({
      // enabled: !entering && entered,
      enabled: !shouldFlyIn,
      onStart: onChangeView,
      initialValue: toTarget[view as CityView] || 0,
      // onStart: () => setView('index'),
      onSpringChange,
      mobileGesture,
      desktopGesture,
      animationConfig: {
        duration: 800,
        easing: easings.easeInOutQuad,
      },
    }),
    [shouldFlyIn, onSpringChange, onChangeView, view],
  )

  useGesture(gestureController)

  useEffect(() => {
    const flyTo = (
        cityViews.map(({ id }) => id).includes(initialView as CityView)
          ? initialView
          : 'index'
      ) as CityView
    if (shouldFlyIn) {
      camera.position.set(4.6, 3, -15.51)
      camera.rotation.set(0, 0, 0)
      camera.rotateY(-Math.PI * (4.9 / 4))

      goTo({
        ...locations[flyTo],
        delay: 2000,
        config: { duration: 2000, easing: easings.easeInOutCubic },
      }).then(() => {
        useApp.setState({ cameraShouldFlyIn: false })
      })
    } else {
      goTo({
        ...locations[flyTo],
        delay: 2000,
        instant: true,
      })
    }
  }, [shouldFlyIn, goTo, onSpringChange, initialView, camera])
}

export const useViewVisible = (view: string) => {
  const currView = useApp((s) => s.view)
  const shouldFlyIn = useApp((s) => s.cameraShouldFlyIn)
  return useMemo(
    () => !shouldFlyIn && view === currView,
    [currView, view, shouldFlyIn],
  )
}
