/* eslint-disable react-hooks/exhaustive-deps */
import { useSpring } from '@react-spring/three'
import {
  Preload,
  ScreenQuad,
  useDetectGPU,
  useTexture,
} from '@react-three/drei'
import {
  Canvas, createPortal, useFrame, useThree,
} from '@react-three/fiber'
import {
  CopyPass,
  Effect,
  EffectComposer,
  EffectPass,
  FXAAEffect,
  RenderPass,
} from 'postprocessing'
import {
  MutableRefObject,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Euler,
  HalfFloatType,
  PerspectiveCamera,
  Scene,
  ShaderMaterial,
  Vector3,
  WebGLRenderTarget,
} from 'three'
import { ConfigureGL } from './ConfigureGL'
import { fShader, getCanvasUniforms, vShader } from './fadeShader'
import { Stats } from './Stats'
import { SceneViewerProps } from './types'
import { isProduction } from './utils'

// function getTarget(camera: Camera, v: Vector3): void {
//   camera.getWorldDirection(v)
//   v.normalize()
//   v.multiplyScalar(0.1)
//   v.add(camera.position)
// }

const Layers = ({
  fadeTexture,
  layers,
  sharedElements,
  sharedPostprocessing,
  activeLayer,
  // mainTrack,
  statLevel,
}: SceneViewerProps & {
  // mainTrack: MutableRefObject<HTMLElement>
  helperTrack?: MutableRefObject<HTMLElement>
}) => {
  const [screenCamera] = useState(new PerspectiveCamera())
  const [targetScene] = useState(new Scene())
  const savedCameraPositions = useRef<
    Array<{
      position?: Vector3
      target?: Vector3
      rotation?: Euler
    }>
  >(
    layers.map(({ camera }) => ({
      position: camera?.position,
      target: camera?.target,
      rotation: camera?.rotation,
    })),
  )
  const prevLayer = useRef(activeLayer)
  const {
    gl, scene, size, camera,
  } = useThree()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const mixTexture = typeof fadeTexture === 'string' ? useTexture(fadeTexture) : fadeTexture

  const [fadeFbo] = useState(
    new WebGLRenderTarget(
      size.width * gl.getPixelRatio(),
      size.height * gl.getPixelRatio(),
    ),
  )
  const [fadeCopyPass] = useState(new CopyPass(fadeFbo))

  const { composer, savePass } = useMemo(() => {
    const _composer = new EffectComposer(gl, { frameBufferType: HalfFloatType })

    const renderPass = new RenderPass(scene, screenCamera)
    const targetRenderPass = new RenderPass(targetScene, camera)

    const _savePass = new CopyPass()

    _composer.addPass(targetRenderPass)
    _composer.addPass(_savePass)
    _composer.addPass(renderPass)
    // composer.addPass(effectPass)

    return { composer: _composer, savePass: _savePass }
  }, [])

  const uniforms = useMemo(
    () => getCanvasUniforms({
      mainTexture: savePass.texture,
      fadeTexture: fadeCopyPass.texture,
      resolution: [
        size.width * gl.getPixelRatio(),
        size.height * gl.getPixelRatio(),
      ],
      mixTexture,
    }),
    [],
  )

  const material = useMemo(
    () => new ShaderMaterial({
      fragmentShader: fShader,
      vertexShader: vShader,
      uniforms,
    }),
    [],
  )

  const prevSize = useRef({ width: 0, height: 0 })
  useEffect(() => {
    const { height, width } = size
    if (
      prevSize.current.height === height
      && prevSize.current.width === width
    ) {
      return
    }
    const pixelRatio = gl.getPixelRatio()
    composer.setSize(size.width, size.height, true)
    uniforms.resolution.value = [
      size.width * pixelRatio,
      size.height * pixelRatio,
    ]
    prevSize.current = { height, width }
    // good for debugging lighting
    // targetScene.background = new THREE.Color(0xff0000);
  }, [size, gl])
  const [{ mixRatio }] = useSpring(
    () => ({
      mixRatio: 1,
      onChange: ({ value: { mixRatio: v } }) => {
        material.uniforms.mixRatio.value = v
        // material.uniforms.mixRatio.value = 0.5
      },
      config: { duration: 1000 },
    }),
    [],
  )
  const { tier } = useDetectGPU()
  const initializingLayer = useRef(false)
  // const rendered = use;
  useEffect(() => {
    if (activeLayer === prevLayer.current) return
    initializingLayer.current = true
    // @ts-ignore
    fadeCopyPass.render(gl, savePass.renderTarget, fadeCopyPass.renderTarget)
    mixRatio.set(0)
    prevLayer.current = activeLayer
    mixRatio.start(1, {
      delay: 166,
      onStart: () => {
        initializingLayer.current = false
      },
    })
  }, [activeLayer, mixRatio])

  const layer = useMemo(() => layers?.[activeLayer], [activeLayer, layers])
  const [fxaa] = useState(new FXAAEffect())
  useEffect(() => {
    const effects: Array<Effect> = []
    if (tier > 2) effects.push(fxaa)
    effects.push(
      ...(layer.postprocessing?.effects || []),
      ...(sharedPostprocessing?.effects || []),
    )
    const effectPass = new EffectPass(camera, ...effects)
    composer.addPass(effectPass, 1)
    return () => {
      composer.removePass(effectPass)
      effectPass.dispose()
    }
  }, [layer, sharedPostprocessing, tier])

  useFrame((_, delta) => {
    if (!initializingLayer.current) {
      composer.render(delta)
    }
  }, 1)

  const showStats = !isProduction && statLevel !== undefined

  const Component = useMemo(() => layer.Component, [layer])

  return (
    <>
      {createPortal(
        (
          <>
            <Component camera={savedCameraPositions.current[activeLayer]} />
            {sharedElements || null}
          </>
        ) || <></>,
        targetScene,
      )}
      <ScreenQuad>
        <primitive object={material} attach='material' />
        {/* <meshBasicMaterial color={0xff0000}/> */}
      </ScreenQuad>
      {showStats && <Stats statLevel={statLevel} />}
    </>
  )
}

export const SceneViewer = ({
  frameLoop,
  ...props
}: SceneViewerProps & {
  frameLoop?: 'always' | 'demand' | 'never'
  // mainTrack: MutableRefObject<HTMLElement>
  helperTrack?: MutableRefObject<HTMLElement>
}) => (
  <Canvas
    shadows
    gl={{
      powerPreference: 'high-performance',
      antialias: false,
      stencil: false,
      depth: false,
      toneMappingExposure: 0.65,
    }}
    frameloop={frameLoop}
    // disables ACESFilmicToneMapping
    // will need to tone map through postprocessing once transitions postprocessed
  >
    <Suspense fallback={null}>
      <ConfigureGL />
      <Layers {...props} />
      <Preload all />
    </Suspense>
  </Canvas>
)

// export const SceneViewerTest = (props: SceneViewerProps) => {
//   const mainTrack = useRef<HTMLDivElement>(null)
//   const helperTrack = useRef<HTMLDivElement>(null)
//   return (
//     <HStack spacing={0} h='100%' w='100%'>
//       {/* @ts-ignore */}
//       <SceneViewer {...props} mainTrack={mainTrack} helperTrack={helperTrack} />
//       <Flex bg='red' h='100%' ref={mainTrack} flex={1}></Flex>
//       <Flex h='100%' ref={helperTrack} flex={1}></Flex>
//     </HStack>
//   )
// }
