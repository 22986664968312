import { useTimeout } from '@chakra-ui/react'
import { BloomEffect } from 'postprocessing'
import {
  MutableRefObject, useMemo, useRef,
} from 'react'
import { useLocation } from 'react-router-dom'
import { FADE_IMAGE_SRC } from '../../../consts'
import { useApp } from '../../../store'
import { preloadAll } from '../../../utils'
import { NavBar } from '../../NavBar'
import { SceneViewer } from '../../SceneViewer'
import { cityLayer } from './layers/City'
import { soundLayer } from './layers/Sound'
import { theaterLayer } from './layers/Theater'
import { Environment } from './shared/Environment'

preloadAll()

const layers = [cityLayer, theaterLayer, soundLayer]

const sharedPostprocessing = {
  effects: [
    new BloomEffect({
      luminanceThreshold: 0.55,
      intensity: 2,
      luminanceSmoothing: 0.2,
    }),
  ],
}

export const Scene = () => {
  const helperTrack = useRef<HTMLDivElement>(null)
  // useInterval(() => setActiveLayer((l) => (l + 1) % 2), 000)
  const { pathname } = useLocation()
  const activeLayer = useMemo(() => {
    if (pathname === '/theatre') return 1
    if (pathname === '/sound') return 2
    return 0
  }, [pathname])

  useTimeout(() => useApp.setState({ entered: true }), 2000)
  return (
    <>
    <SceneViewer
      layers={layers}
      fadeTexture={FADE_IMAGE_SRC}
      sharedElements={<Environment/>}
      sharedPostprocessing={sharedPostprocessing}
      helperTrack={helperTrack as MutableRefObject<HTMLElement>}
      activeLayer={activeLayer}
    />
    <NavBar/>
    </>
  )
}
