import {
  FileValue,
  FLImagePairValue,
  ImageAnnotationValue,
  WithId,
} from '../types'
import {
  Field,
  FieldMap,
  FieldType,
  isField,
  isRecordField,
  SelectField,
} from '../types/forms'

const isRecord = (data?: any) => typeof data === 'object' && !(data instanceof Array) && data !== null
export const processDataForRead = (field: FieldMap, data?: any) => {
  const acc: Record<string, any> = {
    ...(isRecord(data) ? data : {}),
  }
  const entries = Object.entries(field.children)
  const numChildren = entries.length
  for (let i = 0; i < numChildren; i++) {
    const [fieldKey, child] = entries[i]
    const childValue = data?.[fieldKey]
    if (isField(child)) {
      continue
    } else if (isRecordField(child)) {
      const items = Object.entries(childValue || {})
      const { itemField } = child
      if (isField(itemField)) acc[fieldKey] = recordToArray(childValue)
      else {
        acc[fieldKey] = items.map(([key, itemData]) => ({
          _id: key,
          ...processDataForRead(itemField, itemData),
        }))
      }
    } else {
      acc[fieldKey] = processDataForRead(child, childValue)
    }
  }
  return acc
}

// replaces undefined values with empty string/object/array for updateDoc calls
export const processDataForWrite = (field: FieldMap, data?: any) => {
  const acc: Record<string, any> = {
    ...(typeof data === 'object' && data !== null ? data : {}),
  }
  const entries = Object.entries(field.children)
  const numChildren = entries.length
  for (let i = 0; i < numChildren; i += 1) {
    const [fieldKey, child] = entries[i]
    const childValue = data?.[fieldKey]
    if (isField(child)) {
      switch (child._type) {
        case 'file':
          acc[fieldKey] = childValue || {}
          delete acc[fieldKey].processImageResults
          break
        case 'boolean':
          acc[fieldKey] = childValue || false
          break
        case 'currency':
        case 'number':
          const asNum = parseFloat(data?.[fieldKey] || '')
          if (isNaN(asNum)) acc[fieldKey] = undefined
          else acc[fieldKey] = asNum
          break
        case 'annotations':
          if (childValue) acc[fieldKey] = data[fieldKey]
          break
        default:
          acc[fieldKey] = childValue || ''
          break
      }
    } else if (isRecordField(child)) {
      const { itemField } = child
      if (isField(itemField)) acc[fieldKey] = arrayToRecord(childValue)
      else {
        acc[fieldKey] = childValue.reduce(
          (acc, itemData) => ({
            ...acc,
            [itemData._id]: processDataForWrite(itemField, itemData),
          }),
          {},
        )
      }
    } else {
      acc[fieldKey] = processDataForWrite(child, data?.[fieldKey])
    }
  }
  return acc
}

export const recordToArray = (record?: Record<string, any>) => Object.entries(record || {}).map(([_id, val]) => ({ ...val, _id }))

export const arrayToRecord = (arr?: Array<WithId<Record<string, any>>>) => (arr || []).reduce(
  (acc, curr) => ({
    ...acc,
    [curr._id]: curr,
  }),
    {} as Record<string, any>,
)

const dateHelper = new Date()

const castDate = (date: Date | number | string) => (date instanceof Date ? date : new Date(date))

export const formatDateTime = (
  date: Date | number | string,
  dateStyle: 'long' | 'short' = 'long',
  utc = true,
) => {
  let options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }
  const asDate = castDate(date)
  // if (utc) asDate.setTime(asDate.getTime() - asDate.getTimezoneOffset() * 60 * 1000)
  if (utc) options.timeZone = 'UTC'
  // options.timeZone = 'UTC'
  // if (utc) options.timeZone = 'America/New_York'
  if (dateStyle === 'long') {
    options = {
      ...options,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    }
  }

  return asDate.toLocaleDateString('en-US', options)
}

export const fieldFormats: Record<
  FieldType,
  (val: any, field: Field) => string
> = {
  text: (val) => val || '',
  boolean: (val) => (val ? 'Yes' : 'No'),
  currency: (val: number) => `$${val.toFixed(2)}`,
  date: (val: number) => {
    if (!val) return ''
    dateHelper.setTime(val)
    return dateHelper.toLocaleDateString()
  },
  flImagePair: (val?: FLImagePairValue) => 'cant be formatted',
  file: (val?: FileValue) => val?.title || '',
  annotations: (val?: ImageAnnotationValue) => `Annotations: ${val?.annotations.length || 0}`,
  number: (val: number) => (val === undefined ? '' : `${val}`),
  select: (val, field: Field) => (val
    ? (field as SelectField).options.find((o) => o.id === val)?.text
        || 'option not found'
    : ''),
  textarea: (val) => val,
  time: (val) => val,
}

export const format = (field: Field, val: any) => fieldFormats[field._type](val, field)

export const getItemName = (label?: string) => {
  if (!label) return ''
  if (label[label.length - 1].toLowerCase() === 's') return label.slice(0, label.length - 1)
  return label
}
export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
export const capitalizeFirstLetterOfEachWord = (str: string) => str.split(' ').map(capitalizeFirstLetter).join(' ')
