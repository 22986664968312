import { extendTheme } from '@chakra-ui/react'
import { gradients, gradientToCssGradientProp } from '@loneworld/shared'

export const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        color: 'black',

        fontFamily: 'Gloria Hallelujah',
      },
      variants: {
        solid: {
          color: 'white',
          fontSize: '1.4rem',
          background: 'transparent',
          _focus: {
            background: 'transparent',
          },
          _hover: {
            background: 'transparent',
          },
        },
      },
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: gradientToCssGradientProp(gradients.silver),
        },
        track: {
          bg: 'blackAlpha.800',
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: 'Gloria Hallelujah',
      },
    },
    Text: {
      baseStyle: {
        fontFamily: 'Inter',
        whiteSpace: 'pre-wrap',
        fontSize: '1.3rem',
        fontWeight: '500',
      },
    },
  },
})
