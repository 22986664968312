import {
  Box,
  Center,
  HStack, SliderProps, StackProps, VStack,
} from '@chakra-ui/react'
import {
  ColorField,
  colorValueToHex,
  Gradient,
  gradients,
} from '@loneworld/shared'
import { ShadowText } from 'components/shared/ShadowText'
import { forwardRef, useCallback, useMemo } from 'react'
import { useInputImperativeHandle } from '../hooks'
import { InputSlider } from '../InputSlider'
import { InputProps, InputRef } from '../types'

const ColorComponentSlider = ({
  label,
  stackProps,
  gradient,
  value,
  ...props
}: {
  label: string
  stackProps?: StackProps
  gradient: Gradient
} & SliderProps) => (
    <InputSlider
      label={label}
      value={value}
      min={0}
      step={1}
      max={255}
      stackProps={{ width: '100%', ...stackProps }}
      gradient={gradient}
      {...props}
    />

)

export const ColorComponent = forwardRef<InputRef, InputProps<ColorField>>(({
  input: { onChange, value },
  field,
  meta,
}, ref) => {
  // my colors: {r: 0, g: 0, b: 0, a: 0}
  // input colors: '#rrggbbaa'
  const { withAlpha, defaultValue } = field
  const { active } = meta || {}

  useInputImperativeHandle(ref)

  const handleChange = useCallback(
    (key: 'r' | 'g' | 'b' | 'a', val: number) => {
      if (withAlpha) {
        onChange({
          r: 0,
          g: 0,
          b: 0,
          a: 1,
          ...value,
          [key]: val,
        })
      } else {
        onChange({
          r: 0,
          g: 0,
          b: 0,
          ...value,
          [key]: val,
        })
      }
    },
    [value, onChange, withAlpha],
  )

  const withDefault = useMemo(() => ({ ...defaultValue, ...value }), [defaultValue, value])
  const asHex = useMemo(
    () => colorValueToHex(!!withAlpha, withDefault),
    [withDefault, withAlpha],
  )
  return (
    <HStack w='100%'>
      <Box
        boxShadow='inset 0 0 12px rgba(255,255,255,0.4), 0 0 4px rgba(0,0,0,0.4)'
        p={2}
        bg='blackAlpha.800'
        borderRadius='full'
      >
        <Center
          transition='background 300ms'
          bg={asHex}
          width='80px'
          h='80px'
          borderRadius='full'
        >
          <ShadowText style={{ fontSize: '1.3rem' }}>{asHex}</ShadowText>
        </Center>
      </Box>
      <VStack
        spacing={1}
        flex={1}
        px={2}
        align='flex-start'
        borderRadius={6}
        transition='all 400ms'
        boxShadow={`0 0 5px ${active ? '#ffffff' : 'transparent'}`}
      >
        <ColorComponentSlider
          label='R'
          gradient={gradients.red}
          defaultValue={defaultValue.r}
          value={value?.r}
          onChange={(updatedRed) => handleChange('r', updatedRed)}
        />
        <ColorComponentSlider
          label='G'
          defaultValue={defaultValue.g}
          gradient={gradients.green}
          value={value?.g}
          onChange={(updatedGreen) => handleChange('g', updatedGreen)}
        />
        <ColorComponentSlider
          label='B'
          gradient={gradients.blue}
          defaultValue={defaultValue.b}
          value={value?.b}
          onChange={(updatedBlue) => handleChange('b', updatedBlue)}
        />
        {withAlpha ? (
          <ColorComponentSlider
            label='A'
            gradient={gradients.midnight}
            defaultValue={1}
            value={value?.a}
            onChange={(updatedAlpha) => handleChange('a', updatedAlpha)}
          />
        ) : null}
      </VStack>
    </HStack>
  )
})
