import { useSpring } from '@react-spring/core'
import { useEffect, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useThrottler } from '../bounce'
import { getScrollGesture } from './desktop/oned/scroll'
import { getScrollSnapGesture } from './desktop/oned/scrollSnap'
import { getMobilePanGesture } from './mobile/oned/pan'
import { Gesture, GestureConfig, GestureToController } from './types'
import { getSwipeGesture } from './universal/oned/swipe'

export const gestures: Record<Gesture['_type'], GestureToController> = {
  MOBILE_PAN_GESTURE: getMobilePanGesture as GestureToController,
  SCROLL_GESTURE: getScrollGesture as GestureToController,
  SCROLL_SNAP_GESTURE: getScrollSnapGesture as GestureToController,
  SWIPE_GESTURE: getSwipeGesture as GestureToController,
}

export const useGesture = (config: GestureConfig) => {
  const {
    initialValue = 0,
    enabled = true,
    desktopGesture,
    mobileGesture,
    onRest,
    onStart,
    onSpringChange,
  } = config
  const [{ x }, api] = useSpring(
    () => ({
      x: initialValue,
      onChange: ({ value: { x: val } }) => {
        if (onSpringChange) {
          onSpringChange(val)
        }
      },
      onRest: ({ value: { x: val } }) => {
        if (onRest) onRest(val)
        if (onSpringChange) onSpringChange(val)
      },
      // config: animationConfig,
    }),
    [onSpringChange, onRest, initialValue],
  )
  const throttle = useThrottler({ timeout: 100 })
  const desktop = useMemo(
    () => (desktopGesture
      ? gestures[desktopGesture._type](desktopGesture)
      : undefined),
    [desktopGesture],
  )
  const mobile = useMemo(
    () => (mobileGesture ? gestures[mobileGesture._type](mobileGesture) : undefined),
    [mobileGesture],
  )

  useEffect(() => {
    if (!enabled) return () => {}
    let unsubscribe: (() => void) | undefined
    if (isMobile) {
      unsubscribe = mobile?.subscribe({
        controller: config,
        x,
        initialValue,
        onChange: (v) => {
          if (onStart) onStart(v)
          x.start(v)
        },
        throttle,
      })
    } else {
      unsubscribe = desktop?.subscribe({
        controller: config,
        initialValue,
        x,
        onChange: (v) => {
          if (onStart) onStart(v)
          x.start(v)
        },
        throttle,
      })
    }
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [config, enabled, desktop, mobile, throttle, x, onStart, initialValue])
  return api
}
