import { ChevronLeftIcon, EmailIcon } from '@chakra-ui/icons'
import { Button, Image, VStack } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { useNavigate } from 'react-router-dom'
import { useMounted } from '../../../../../hooks/useMounted'

export const Links = () => {
  const navigate = useNavigate()
  const mounted = useMounted(500)
  return (
  <Html
    transform
    center
    draggable={false}
    // scale={[0.2,0.2,0.2]}
    position={[55, 10, -20]}
    rotation={[0, -Math.PI / 2, 0]}
    style={{
      width: '1000px',
      userSelect: 'none',
      touchAction: 'none',
      opacity: mounted ? 1 : 0,
      transition: 'opacity 500ms',
      pointerEvents: 'none',
      // background: 'rgba(0,0,0,0.5)',
      paddingBottom: '8rem',
    }}
  >
    <VStack>
      <VStack spacing='50px'>
      <Button
          onClick={() => navigate('/')}
          px='50px'
          borderRadius='100px'
          variant='outline'
          pointerEvents='auto'
          color='#dcd156'
          background='#444'
          fontSize='110px'
        >
          <ChevronLeftIcon/>
          EXIT THEATRE
        </Button>
        <Button
          onClick={() => window.open('https://vimeo.com/791539015', '_blank')}
          px='50px'
          borderRadius='100px'
          variant='outline'
          pointerEvents='auto'
          color='#e23e85'
          background='#dcd156'
          fontSize='110px'
        >
          <Image w='60px' mr='20px' color='#fff' src='/assets/svg/play-white.png'/>
           LWTV EPISODE 1
        </Button>
        <Button
          onClick={() => window.open('https://vimeo.com/988257799', '_blank')}
          px='50px'
          borderRadius='100px'
          variant='outline'
          pointerEvents='auto'
          color='white'
          background='#89b0ba'
          fontSize='110px'
        >
          <Image w='60px' mr='20px' color='#fff' src='/assets/svg/play-white.png'/>
           LWTV EPISODE 2
        </Button>
      </VStack>
    </VStack>
  </Html>
  )
}
