import {
  Button, Center, CircularProgress, HStack, Image, Text,
} from '@chakra-ui/react'
import {
  FieldMap, FileField, Poster, TextField, TheaterConfig, WithId,
} from '@loneworld/shared'
import { useMemo } from 'react'
import { THEATER_CONFIG_REF } from '../../backend/db'
import { useDocument } from '../../backend/hooks'
import { ListEdit } from '../forms/ListEdit'

const posterImageField: FileField = {
  _type: 'file',
  accept: ['image'],
  placeholder: 'Image',
}

const posterTitleField: TextField = {
  _type: 'text',
  placeholder: 'Title',
}

const posterVideoIdField: TextField = {
  _type: 'text',
  placeholder: 'Video ID',
}

const posterField: FieldMap = {
  name: 'Poster',
  children: {
    image: posterImageField,
    title: posterTitleField,
    videoId: posterVideoIdField,
  },
}

const PosterPreview = ({ value }: { value: WithId<Poster> }) => (
  <Center w='100%' flex={1} px={3}>
    <a href={`https://www.youtube.com/watch?v=${value.videoId}`} target='_blank' rel='noreferrer'>
      <Button variant='ghost' _hover={{ bg: 'whiteAlpha.300' }}>
        <Image src='/assets/images/social/yt.svg' alt='YouTube' w='42px' />
        <Text ml={2} color='gray.50' fontSize='xl' fontFamily='Gloria Hallelujah' textShadow='1px 1px 3px black'>
          Open on YouTube
        </Text>
      </Button>
    </a>
  </Center>
)

const TheaterConfigForm = ({ config }: { config: WithId<TheaterConfig> | null }) => {
  const postersValue = useMemo(
    () => ({
      poster1: config?.poster1,
      poster2: config?.poster2,
      poster3: config?.poster3,
    }),
    [config],
  )
  return (
    <ListEdit<Poster>
      value={postersValue}
      ItemPreview={PosterPreview}
      baseStoragePath='theater/config'
      documentRef={THEATER_CONFIG_REF}
      propertyPath={['poster1', 'poster2', 'poster3']}
      itemName='poster'
      getItemTitle={(item) => item.title}
      itemField={posterField}
      expandedItemHeight={400}
      getItemBackgroundUrl={(item) => item.image?.thumbnailUrl || item.image?.url}
      listName='Poster Frame'
    />
  )
}

export const TheaterConfigView = () => {
  const { data: theaterConfig, isLoading, error } = useDocument(THEATER_CONFIG_REF)
  let body = null

  if (!theaterConfig && isLoading) {
    body = (
      <HStack pt={8}>
        <CircularProgress isIndeterminate color='gray' size={5} />
        <Text fontFamily='Gloria Hallelujah' color='white'>
          Loading theater config...
        </Text>
      </HStack>
    )
  } else if (error) {
    body = (
      <Text pt={8} color='red.300'>
        {error.message}
      </Text>
    )
  } else {
    body = <TheaterConfigForm config={theaterConfig} />
  }
  return (
    <Center p={2} w='100%'>
      {body}
    </Center>
  )
}
