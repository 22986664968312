import { create } from 'zustand'

export const useScreen = create(() => ({
  width: 0,
  height: 0,
}))

if (typeof window !== 'undefined') {
  useScreen.setState({ width: window.document.body.clientWidth, height: window.document.body.clientHeight })
  window.addEventListener('resize', () => {
    const { clientHeight, clientWidth } = window.document.body
    useScreen.setState({ width: clientWidth, height: clientHeight })
  })
}
