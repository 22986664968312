import { Image } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { useMounted } from '../../../../../hooks/useMounted'
import { useApp } from '../../../../../store'
import { ImageButton } from '../../../../shared/ImageButton'

export const Splash = () => {
  const view = useApp((s) => s.view)
  const shouldFlyIn = useApp((s) => s.cameraShouldFlyIn)
  const mounted = useMounted(500)
  const show = useMemo(() => mounted && !shouldFlyIn && view === 'index', [view, mounted, shouldFlyIn])
  const navigate = useNavigate()
  return (
    <>
      <Html
        center
        transform
        pointerEvents={show ? 'auto' : 'none'}
        style={{ transition: `opacity 500ms ease ${show ? 500 : 0}ms`, opacity: show ? 1 : 0 }}
        position={[2.44, 2.4, 4]}
        rotation={[0, Math.PI, 0]}
        // scale={[.8]}
      >
        <ImageButton
          url={'/assets/images/buttons/theatre.gif'}
          alt='Enter theatre'
          width={120}
          height={50}
          onClick={() => {
            navigate('/theatre')
          }}
        />
      </Html>
      <Html
        center
        transform
        pointerEvents={'none'}
        // className={`threed-button ${show ? 'delay500' : 'hidden'}`}
        position={[2.4, 4.6, 4]}
        style={{ transition: `opacity 500ms ease ${show ? 500 : 0}ms`, opacity: show ? 1 : 0 }}
        rotation={[0, Math.PI, 0]}
        scale={[0.8, 0.8, 0.8]}
      >
        <Image
          src={`/assets/images/buttons/${isMobile ? 'swipe' : 'scroll'}.gif`}
          userSelect='none'
          alt='Scroll to explore theatre'
          height='60px'
        />
      </Html>
    </>
  )
}
