import { CityConfig } from '@loneworld/shared'
import { easings, useSpring } from '@react-spring/core'
import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import {
  Group as IGroup, PointLight, RepeatWrapping, Texture, TextureLoader, Vector3Tuple,
} from 'three'
import { CITY_CONFIG_REF } from '../../../../../../backend/db'
import { useDocument } from '../../../../../../backend/hooks'
import { usePointerHover } from '../../../../../../hooks/usePointerHover'
import { useViewVisible } from '../hooks'
import { createShaderWithTextures } from './shader'

const positions: Array<Vector3Tuple> = [
  [-27, 3, 1],
  // [-28, 1.5, 1],
  // [-33, 6, 1],
  // [-33, 1.5, 1],
  // [-36, 6, 1],
  [-34, 3, 1],
]

export const BusStop = () => {
  const lightsOn = useViewVisible('busstop')
  const [initOn] = useState(lightsOn)
  const [group] = useState(new IGroup())
  const { data } = useDocument<CityConfig>(CITY_CONFIG_REF)
  const textureUrls = useMemo<Array<string>>(
    () => data?.busStopAds.map((img) => img.image.url as string)?.filter((a) => !!a) || [],
    [data],
  )
  const textures = useMemo(
    () => textureUrls.map((url) => new TextureLoader().load(url, (t) => {
      const tex = t
      tex.flipY = false
      tex.repeat.set(-1, 1)
      tex.wrapS = RepeatWrapping
      tex.wrapT = RepeatWrapping
    })),
    [textureUrls],
  )

  const prevTextures = useRef<Array<Texture>>([])
  useEffect(() => {
    prevTextures.current.forEach((t) => t.dispose())
    prevTextures.current = textures
  }, [textures])

  const [busStopImageIndex, setBusStopImageIndex] = useState(0)
  useEffect(() => {
    if (textureUrls.length < 2) return () => {}
    const t = setInterval(() => {
      setBusStopImageIndex((i) => (i + 1) % textureUrls.length)
    }, 5000)
    return () => {
      clearInterval(t)
    }
  }, [textureUrls])

  const busStopItem = useMemo(() => data?.busStopAds[busStopImageIndex], [busStopImageIndex, data])
  const material = useMemo(() => createShaderWithTextures(textures), [textures])
  useSpring({
    progress: busStopImageIndex / (textures.length || 1),
    config: { duration: 2000, easing: easings.easeInOutCubic },
    onChange: ({ value: { progress } }) => {
      material.uniforms.progress.value = progress
    },
  })
  useSpring({
    intensity: lightsOn ? 2.2 : 0.8,
    delay: lightsOn ? 500 : 0,
    onChange: ({ value: { intensity } }) => {
      group.children.forEach((c) => {
        const light = c as PointLight
        if (light.isLight) light.intensity = intensity
      })
    },
  })
  const { onPointerOut, onPointerOver } = usePointerHover()
  return (
    <primitive object={group}>
      <mesh
      onPointerOut={busStopItem?.link ? onPointerOut : undefined}
      onPointerOver={busStopItem?.link ? onPointerOver : undefined}
      onClick={(e) => {
        e.stopPropagation()
        if (busStopItem?.link) {
          window.open(busStopItem.link, '_blank')
        }
      }}
      rotation={[0, 0, Math.PI]} position={[-29.35, 2.57, 5.35]} material={material}>
        <planeGeometry args={[2.5, 4.6, 2]} />
      </mesh>
      {positions.map((pos, i) => (
        <pointLight key={`light_${i}`} distance={15} position={pos} intensity={initOn ? 0.8 : 0.2} />
      ))}
    </primitive>
  )
}
