import { DeleteIcon, DragHandleIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  HStack,
  IconButton,
  StackProps,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  Field,
  formatField,
  Formatter,
  getFieldName,
  IFormElement,
  isField,
  RecordField,
} from '@loneworld/shared'
import {
  CloseButton, ResetButton, SaveButton, TooltipIconButton,
} from 'components/shared/Buttons'
import { CollapseCenter } from 'components/shared/CollapseCenter'
import { CollapseHorizontal } from 'components/shared/CollapseHorizontal'
import { ShadowText } from 'components/shared/ShadowText'
import {
  CSSProperties,
  FC,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd'
import { Field as FinalField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { ExpandOnMount } from '../../shared/ExpandOnMount'
import { Condition } from '../Condition'
import { BooleanComponent } from './boolean'
import { ColorComponent } from './color'
import { FileComponent, FileView } from './file'
import { useInputImperativeHandle, useInputStyle } from './hooks'
import { NumberComponent } from './number'
import { SelectComponent } from './select'
import { TextComponent } from './text'
import { TextAreaComponent } from './textarea'
import {
  FormProps, InputProps, InputRef, InputSize,
} from './types'
import { defaultValidate } from './utils'

const makeOnDragEndFunction = (fields: any) => (result: DropResult) => {
  // dropped outside the list
  if (!result.destination) {
    return
  }
  fields.move(result.source.index, result.destination.index)
}

export const RecordComponent = forwardRef<InputRef, InputProps<RecordField>>(
  ({ field, input }, ref) => {
    const { name } = input || {}
    const itemName = getFieldName(field.itemField)
    const itemFieldIsField = isField(field.itemField)
    useInputImperativeHandle(ref)
    return (
      <Flex w='100%' flexDir='column'>
        {field.label ? (
          <Flex color='#dedede'>
            <ShadowText>{field.label}</ShadowText>
          </Flex>
        ) : null}
        <FieldArray name={name}>
          {({ fields }) => (
            <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
              <Droppable droppableId='droppable'>
                {(provided) => (
                  <VStack
                    ref={provided.innerRef}
                    w='100%'
                    py={1}
                    spacing={0}
                    {...provided.droppableProps}
                  >
                    {fields.map((id, idx) => {
                      const indexedField = isField(field.itemField)
                        ? {
                          ...field.itemField,
                          placeholder: `${itemName} ${idx + 1}`,
                        }
                        : {
                          ...field.itemField,
                          name: `${itemName} ${idx + 1}`,
                        }
                      return (
                        <Draggable key={id} index={idx} draggableId={id}>
                          {({ dragHandleProps, draggableProps, innerRef }) => (
                            <div ref={innerRef} {...draggableProps}>
                              <ExpandOnMount style={{ padding: '0.5rem 0.8rem' }} key={id}>
                                <HStack
                                  ref={innerRef}
                                  align='flex-start'
                                  p={2}
                                  spacing={0}
                                  bg='whiteAlpha.100'
                                  borderRadius={6}
                                  boxShadow='0 0 4px white'
                                >
                                  <FormElement
                                    validate
                                    name={itemFieldIsField ? `${id}.value` : id}
                                    field={indexedField}
                                  />
                                  <Flex
                                    flexDir='column'
                                    h='100%'
                                    gap={2}
                                    align='flex-end'
                                    justify='space-between'
                                  >
                                    <IconButton
                                      aria-label='Reorder'
                                      size='xs'
                                      cursor='move'
                                      _hover={{
                                        bg: 'whiteAlpha.400',
                                      }}
                                      margin={0}
                                      icon={<DragHandleIcon width='16px' color='whiteAlpha.700' />}
                                      {...dragHandleProps}
                                    />
                                    <IconButton
                                      aria-label='Delete field'
                                      size='xs'
                                      margin={0}
                                      bg='red.600'
                                      icon={<DeleteIcon width='16px' color='whiteAlpha.700' />}
                                      onClick={() => fields.remove(idx)}
                                    />
                                  </Flex>
                                </HStack>
                              </ExpandOnMount>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                    <Flex>
                      <Button
                        mr='auto'
                        width='auto'
                        size='sm'
                        variant='outline'
                        color='white'
                        fontSize='md'
                        textShadow='0 0 3px black'
                        onClick={() => fields.push(isField(field.itemField) ? '' : { id: `${Date.now()}` })
                        }
                      >
                        + NEW
                      </Button>
                    </Flex>
                  </VStack>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </FieldArray>
      </Flex>
    )
  },
)

const Components: {
  [K in Field['_type']]: FC<InputProps<Extract<Field, { _type: K }>>>
} = {
  text: TextComponent,
  file: FileComponent,
  boolean: BooleanComponent,
  select: SelectComponent,
  textarea: TextAreaComponent,
  number: NumberComponent,
  color: ColorComponent,
  record: RecordComponent,
}

const InputPlaceholder = ({ field, size = 'md' }: { field: Field; size?: InputSize }) => {
  const { placeholder } = field

  const fontSize = useMemo(() => {
    switch (size) {
      case 'sm':
        return 0.9
      case 'md':
        return 1
      case 'lg':
        return 1.2
      default:
        return 1
    }
  }, [size])
  if (!placeholder) return null
  return (
    <Flex>
      <ShadowText style={{ fontSize: `${fontSize + 0.2}rem`, whiteSpace: 'nowrap' }}>
        {field.placeholder}
      </ShadowText>
    </Flex>
  )
}

export const InnerInput = <T extends Field>(
  { stackProps, ...props }: InputProps<T> & { stackProps?: StackProps },
  ref: ForwardedRef<InputRef>,
) => {
  const {
    field,
    meta: { error, active, touched },
    size = 'md',
  } = props
  const color = useMemo(() => {
    if (error && touched) return '#ff7777'
    if (active) return 'rgba(255,255,255,0.7)'
    return 'transparent'
  }, [error, active, touched])
  const styles = useMemo(() => {
    switch (field._type) {
      case 'text':
      case 'textarea':
      case 'number':
        return { background: 'whiteAlpha.200', boxShadow: `0 0 7px ${color}` }
      default:
        return {}
    }
  }, [field, color])

  const Component = Components[field._type] as FC<InputProps<T>>
  const isRecordField = field._type === 'record'
  const fontSize = useMemo(() => {
    switch (size) {
      case 'sm':
        return 0.9
      case 'md':
        return 1
      case 'lg':
        return 1.2
      default:
        return 1
    }
  }, [size])
  return (
    // @ts-ignore
    <Flex flexFlow='column' width='100%' py={1} px={2} {...stackProps}>
      <InputPlaceholder field={field} size={size} />
      {field.label ? (
        <Flex>
          {typeof field.label === 'string' ? (
            <ShadowText
              style={{
                fontSize: `${fontSize}rem`,
                lineHeight: 1,
                color: '#dedede',
              }}
            >
              {field.label}
            </ShadowText>
          ) : (
            field.label
          )}
        </Flex>
      ) : null}
      <Flex width='100%' borderRadius={6} overflow='hidden' {...styles}>
        <Component ref={ref} {...props} />
      </Flex>
      {isRecordField ? null : (
        <Collapse in={!!error && !!touched && !active}>
          <Text fontSize='sm' px={4} color='#ffaaaa'>
            {error}
          </Text>
        </Collapse>
      )}
    </Flex>
  )
}

export const Input = forwardRef<InputRef, InputProps<Field>>(InnerInput)

const ReadOnlyFieldBody = ({
  value,
  field,
  inputStyle,
}: {
  value: any
  field: Field
  inputStyle?: CSSProperties
}) => {
  const formatter = useMemo(() => formatField[field._type] as Formatter<Field>, [field])
  switch (field._type) {
    case 'file':
      return (
        <Box height='150px'>
          <FileView value={value} />
        </Box>
      )
    default:
      return (
        <Text
          borderRadius={4}
          color={value ? 'white' : 'whiteAlpha.600'}
          width='100%'
          bg='whiteAlpha.300'
          style={inputStyle}
        >
          {formatter(value, field) || 'None'}
        </Text>
      )
  }
}

const ReadOnlyField = ({
  value,
  field,
  inputStyle,
  inputStackProps,
  size,
  horizontal,
}: {
  value: any
  field: Field
  inputStyle?: CSSProperties
  size?: InputSize
  inputStackProps?: StackProps
  horizontal?: boolean
}) => (
  <Flex
    // @ts-ignore
    flexFlow={horizontal ? 'row' : 'column'}
    gap={horizontal ? 2 : 1}
    px={1}
    width='100%'
    align={horizontal ? 'center' : 'flex-start'}
    {...inputStackProps}
  >
    <InputPlaceholder field={field} size={size} />
    <ReadOnlyFieldBody field={field} value={value} inputStyle={inputStyle} />
  </Flex>
)

const StandaloneInputInner = <F extends Field>(
  {
    onChange,
    value,
    field,
    onBlur,
    onFocus,
    error,
    focusOnMount,
    style,
    stackProps,
    size,
  }: {
    onChange: (v?: any) => void
    value?: any
    field: F
    onBlur?: () => void
    onFocus?: () => void
    style?: CSSProperties
    focusOnMount?: boolean
    size?: InputProps<F>['size']
    stackProps?: StackProps
    error?: string
  },
  ref: ForwardedRef<InputRef>,
) => {
  const [isActive, setActive] = useState(false)
  useEffect(() => {
    if (focusOnMount && ref) {
      (ref as MutableRefObject<InputRef | null>).current?.focus()
    }
  }, [focusOnMount, ref])
  return (
    <Input
      ref={ref}
      field={field}
      style={style}
      size={size}
      stackProps={stackProps}
      input={{
        name: '',
        onChange: (e: any) => {
          if (e?.target) onChange(e.target?.value)
          else onChange(e)
        },
        value: value as any,
        onBlur: () => {
          setActive(false)
          if (onBlur) onBlur()
        },
        onFocus: () => {
          setActive(true)
          if (onFocus) onFocus()
        },
      }}
      meta={{ error, active: isActive }}
    />
  )
}

export const StandaloneInput = forwardRef(StandaloneInputInner)

export const Editable = <T extends Field>({
  field,
  value,
  size,
  horizontal,
  inputStackProps,
  onSubmit,
}: {
  field: T
  value: any
  inputStackProps?: StackProps
  horizontal?: boolean
  size?: InputProps<T>['size']
  onSubmit: (updated: any) => Promise<void>
}) => {
  const [curr, setCurr] = useState<T['value']>(value)
  const [isEditing, setIsEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const toast = useToast()
  const inputRef = useRef<InputRef | null>(null)
  const handleSubmit = useCallback(() => {
    setIsSubmitting(true)
    onSubmit(curr)
      .then(() => {
        setIsEditing(false)
        setIsSubmitting(false)
      })
      .catch((err: any) => {
        setIsSubmitting(false)
        toast({
          title: 'Error',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
  }, [curr, onSubmit, toast])

  useEffect(() => {
    setCurr(value)
  }, [isEditing, value])

  const dirty = useMemo(() => curr !== value, [curr, value])
  const inputStyle = useInputStyle(size)
  return (
    <HStack width='100%' borderRadius={6} spacing={0}>
      <Box flex={1}>
        {isEditing ? (
          <StandaloneInput
            focusOnMount
            field={field}
            onChange={setCurr}
            ref={inputRef}
            value={curr}
            stackProps={{
              flexFlow: horizontal ? 'row' : 'column',
              gap: horizontal ? 2 : 1,
              align: horizontal ? 'center' : 'flex-start',
              ...inputStackProps,
            }}
            size={size}
          />
        ) : (
          <ReadOnlyField inputStyle={inputStyle} value={value} field={field} />
        )}
      </Box>
      <Flex flexFlow={horizontal ? 'row' : 'column'}>
        {horizontal ? (
          <CollapseHorizontal width={58} active={isEditing && dirty}>
            <ResetButton onClick={() => setCurr(value)} size='xs' />
            <SaveButton isLoading={isSubmitting} onClick={handleSubmit} ml={1} size='xs' />
          </CollapseHorizontal>
        ) : (
          <CollapseCenter in={isEditing && dirty}>
            <ResetButton onClick={() => setCurr(value)} size='xs' />
            <SaveButton isLoading={isSubmitting} onClick={handleSubmit} ml={1} size='xs' />
          </CollapseCenter>
        )}
        <CollapseHorizontal width={30} active={isEditing}>
          <CloseButton
            size='xs'
            onClick={() => {
              setIsEditing(false)
              setCurr(value)
            }}
            shouldConfirm={dirty}
          />
        </CollapseHorizontal>
        <CollapseHorizontal width={30} active={!isEditing}>
          <Center>
            <TooltipIconButton
              onClick={() => {
                setIsEditing(true)
              }}
              size='xs'
              label='EDIT'
              icon={<EditIcon width={4} />}
            />
          </Center>
        </CollapseHorizontal>
      </Flex>
    </HStack>
  )
}

const FieldComponent = ({
  field,
  name,
  validate,
}: {
  field: Field
  name: string
  validate?: boolean
}) => (
  <FinalField
    validate={validate ? field.validate || ((d) => defaultValidate(field, d)) : undefined}
    key={name}
    name={name}
    render={(props) => <Input field={field as Field} {...props} />}
  />
)

const FormElementBody = ({
  name,
  field,
  validate,
}: {
  name: string
  field: IFormElement
  validate?: boolean
}) => {
  if (isField(field)) {
    return <FieldComponent key={name} name={name} field={field} validate={validate} />
  }
  return <FormElement isChild validate={validate} key={name} name={name} field={field} />
}

export const FormElement = ({
  field,
  name,
  validate,
  isChild,
}: {
  field: FormProps['field']
  name?: string
  validate?: boolean
  isChild?: boolean
}) => {
  const fields = useMemo(() => {
    if (isField(field)) {
      return [{ name: name || 'value', field }]
    }
    return Object.entries(field.children).map(([childName, childField]) => ({
      name: `${name ? `${name}.` : ''}${childName}`,
      field: childField,
    }))
  }, [field, name])
  const displayedName = useMemo(() => (isField(field) ? null : field.name), [field])
  return (
    <VStack
      minW='300px'
      borderLeft={isChild ? '4px solid #ffffff77' : 'none'}
      align='flex-start'
      spacing={0}
      w='100%'
    >
      {displayedName ? (
        <ShadowText style={{ fontSize: '1.2rem', paddingLeft: '0.5rem' }}>
          {displayedName}
        </ShadowText>
      ) : null}
      {fields.map((f) => {
        const { condition } = f.field
        const body = condition ? (
          <Condition key={f.name} condition={condition} basePath={name || ''}>
            <FormElementBody name={f.name} field={f.field} validate={validate} />
          </Condition>
        ) : (
          <FormElementBody key={f.name} name={f.name} field={f.field} validate={validate} />
        )

        return (
          <Box key={f.name} w='100%'>
            {body}
          </Box>
        )
      })}
    </VStack>
  )
}
