import { gradients } from '../constants'
import {
  ColorValue, Gradient, Gradients, GradientValue,
} from '../types'

export function componentToHex(c: number) {
  const hex = Math.max(0, Math.round(c)).toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

export function rgbToHex(r: number, g: number, b: number) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

export const gradientToCssProp = ({ r, g, b }: Gradient, direction: string = 'to right') => `linear-gradient(${direction}, rgb(${r.b},${g.b},${b.b}) 0%, rgb(${r.b + r.m},${g.b + g.m},${b.b + b.m}) 100%)`

export const getGradientColor = ({ b, m }: GradientValue, x: number) => m * x + b
export const getColor = (key: keyof Gradients, x: number) => {
  const { r, g, b } = gradients[key] || gradients.blue
  return `${rgbToHex(
    getGradientColor(r, x),
    getGradientColor(g, x),
    getGradientColor(b, x),
  )}ff`
}

export const colorValueToHex = (withAlpha: boolean, value?: ColorValue) => {
  if (!value) return '#00000000'
  const {
    r, g, b, a,
  } = value
  const red = (r || 0).toString(16).padStart(2, '0')
  const green = (g || 0).toString(16).padStart(2, '0')
  const blue = (b || 0).toString(16).padStart(2, '0')
  const alpha = (a === undefined ? 1 : a).toString(16).padStart(2, '0')
  return `#${red}${green}${blue}${withAlpha ? alpha : ''}`
}
