import { CityView, View } from '../types/views'

export const cityViews: Array<View<CityView>> = [
  { name: 'Home', path: '/', id: 'index' },
  { name: 'Enter Sound', path: '/coming-soon', id: 'coming-soon' },
  { name: 'Contact', path: '/contact', id: 'contact' },
  { name: 'Bus Stop', path: '/busstop', id: 'busstop' },
  { name: 'Shop', path: '/shop', id: 'shop' },
]

export const cityViewIds: Array<CityView> = cityViews.map((v) => v.id)
