// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: 'AIzaSyBNRqUrUNuynA7hCZvfuPCyAmVqaSTKz_I',
  authDomain: 'loneworld-web.firebaseapp.com',
  projectId: 'loneworld-web',
  storageBucket: 'loneworld-web.appspot.com',
  messagingSenderId: '706489604604',
  appId: '1:706489604604:web:7795a159d65c85b412ba79',
  measurementId: 'G-JJSL9FMGSX',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
