import {
  CSSProperties, Ref, useImperativeHandle, useMemo, useRef,
} from 'react'
import { InputRef, InputSize } from './types'

const inputPaddingY: Record<InputSize, number> = {
  xs: 0.25,
  sm: 0.35,
  md: 0.5,
  lg: 0.5,
  xl: 0.5,
}

const inputPaddingX: Record<InputSize, number> = {
  xs: 0.5,
  sm: 0.75,
  md: 0.75,
  lg: 0.75,
  xl: 0.75,
}
const getInputPadding = (size: InputSize) => {
  const paddingY = inputPaddingY[size]
  const paddingX = inputPaddingX[size]
  return `${paddingY}rem ${paddingX}rem`
}
const fontSize: Record<InputSize, string> = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
}

export const useInputStyle = (size?: InputSize) => useMemo<Partial<CSSProperties>>(
  () => ({
    padding: getInputPadding(size || 'md'),
    fontSize: fontSize[size || 'md'],
  }),
  [size],
)

export const useInputImperativeHandle = (
  ref: Ref<InputRef>,
  onFocus?: () => void,
  onBlur?: () => void,
) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    focus: onFocus || (() => inputRef.current?.focus()) || (() => {}),
    blur: onBlur || (() => inputRef.current?.blur()) || (() => {}),
  }))
  return inputRef
}
