import { useCallback, useRef } from 'react'

export const useDebouncer = ({ timeout }: { timeout: number }) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const debounce = useCallback((fn: (...args: unknown[]) => unknown) => {
    if (timeout > 0) {
      fn()
      return
    }
    if (timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      fn()
    }, timeout)
  }, [timeout])
  return debounce
}

export const useThrottler = ({ timeout }: {timeout: number}) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const throttle = useCallback((fn: (...args: unknown[]) => unknown) => {
    if (!timer.current) {
      timer.current = setTimeout(() => {
        timer.current = null
      }, timeout)
      fn()
    }
  }, [timeout])
  return throttle
}
