import {
  Matrix4, Quaternion, Vector3, Vector3Tuple,
} from 'three'

export type Helpers = ReturnType<typeof getHelpers>

export const setRotationFromTarget = (
  position: Vector3Tuple,
  target: Vector3Tuple,
  helpers: Helpers,
) => {
  const {
    positionHelper, targetHelper, rotationHelper, toQ, up,
  } = helpers
  positionHelper.set(...position)
  targetHelper.set(...target)
  rotationHelper.lookAt(positionHelper, targetHelper, up)
  toQ.setFromRotationMatrix(rotationHelper)
}
export const getHelpers = () => {
  const positionHelper = new Vector3()
  const up = new Vector3(0, 1, 0)
  const targetHelper = new Vector3()
  const rotationHelper = new Matrix4()
  const fromQ = new Quaternion()
  const toQ = new Quaternion()
  const quaternionHelper = new Quaternion()
  return {
    positionHelper,
    up,
    targetHelper,
    rotationHelper,
    fromQ,
    toQ,
    quaternionHelper,
  }
}
