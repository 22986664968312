import {
  Box,
  Button,
  ButtonProps,
  Collapse,
  Progress,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import {
  FieldMap, isField, processData, toFormData,
} from '@loneworld/shared'
import { AnyObject, FormApi, FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import {
  FC, useCallback, useMemo, useState,
} from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'
import { getUploadFormFile } from '../../backend/storage'
import { CloseButton } from '../shared/Buttons'
import { FormElement } from './input'
import { FieldFormProps, FieldMapFormProps, FormProps } from './input/types'
import { validateFieldMap } from './input/utils'

export const SubmitButton: FC<
  Pick<FormRenderProps, 'handleSubmit' | 'submitting'> & ButtonProps
> = ({ handleSubmit, submitting, ...props }) => (
  // @ts-ignore
  <Button isLoading={submitting} onClick={handleSubmit} ml='auto' {...props} />
)

export const FieldMapForm: FC<FieldMapFormProps> = ({
  onSubmit,
  field,
  value,
  buttonText = 'SUBMIT',
  onCancel,
  baseStoragePath,
  renderFooter,
}) => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const submitWithToast = useCallback(
    async (data: AnyObject, form: FormApi) => {
      try {
        setIsLoading(true)
        const processed = await processData(
          baseStoragePath || '',
          field,
          getUploadFormFile(setUploadProgress),
          data,
        )
        const res = await onSubmit(processed, form)
        if (res?.[FORM_ERROR]) {
          toast({
            title: 'Error',
            description: res?.[FORM_ERROR],
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
        setIsLoading(false)
        return res
      } catch (err: any) {
        console.log(err)
        if (err?.message) {
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
        setIsLoading(false)
        return { [FORM_ERROR]: (err as any).message || 'An error occurred' }
      }
    },
    [field, onSubmit, toast, baseStoragePath],
  )
  const formValue = useMemo(() => toFormData(field, value), [field, value])
  return (
    <Stack borderRadius={10} w='100%' spacing={3} pos='relative' py={2} px={2}>
      <FinalForm
        mutators={{ ...arrayMutators }}
        initialValues={formValue}
        validate={(v) => validateFieldMap(field, v)}
        onSubmit={submitWithToast}
        subscription={{
          values: true,
          submitting: true,
          dirty: true,
          errors: true,
        }}
        render={({
          handleSubmit, submitting, values, errors, dirty, form,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <FormElement field={field} />
            </form>
            <Collapse in={!!errors?.[FORM_ERROR]}>
              <Text color='red'>{errors?.[FORM_ERROR] || ''}</Text>
            </Collapse>
            <Collapse in={!!(isLoading && uploadProgress)} style={{ width: '100%' }}>
              <Progress borderRadius={4} height='6px' ringColor='whiteAlpha.500' value={uploadProgress} />
            </Collapse>
            {renderFooter ? (
              renderFooter({ handleSubmit, submitting, values })
            ) : (
              <Collapse in={dirty} style={{ display: 'flex' }}>
                <Button
                  onClick={() => form.restart()}
                  size='sm'
                  color='whiteAlpha.600'
                  _hover={{ color: 'whiteAlpha.800' }}
                >
                  RESET
                </Button>
                <SubmitButton
                  size='sm'
                  handleSubmit={handleSubmit}
                  submitting={submitting}
                  color='whiteAlpha.600'
                  _hover={{ color: 'whiteAlpha.800' }}
                >
                  {buttonText}
                </SubmitButton>
              </Collapse>
            )}
          </>
        )}
      />
      {onCancel ? (
        <Box position='absolute' top={2} right={2}>
          <CloseButton size='xs' label='CANCEL' onClick={onCancel} />
        </Box>
      ) : null}
    </Stack>
  )
}

export const FieldForm: FC<FieldFormProps> = ({
  onSubmit, field, value, ...props
}) => {
  const fieldMap = useMemo<FieldMap>(() => ({ children: { value: field } }), [field])
  return (
    <FieldMapForm
      field={fieldMap}
      value={{ value }}
      onSubmit={(data, form) => onSubmit(data.value, form)}
      {...props}
    />
  )
}

export const Form: FC<FormProps> = ({ field, ...props }) => {
  if (isField(field)) {
    return <FieldForm field={field} {...props} />
  }
  return <FieldMapForm field={field} {...props} />
}
