import { ReactElement } from 'react'
import { FileValue } from './files'

export type Condition = (value: AnyObject) => boolean
type BaseField<Value> = {
  placeholder: string
  optional?: boolean
  value?: Value
  defaultValue?: Value
  condition?: Condition
  validate?: (value?: Value) => string | undefined
}
export type TextField = BaseField<string> & {
  _type: 'text'
  type?: 'password' | 'email' | 'text' | 'phone'
  label?: ReactElement | string
}

export type TextAreaField = BaseField<string> & {
  _type: 'textarea'
  label?: ReactElement | string
}

export type SelectField<T = any> = BaseField<string> & {
  _type: 'select'
  options: Array<T>
  label?: string
  renderOption: (opt: T) => ReactElement
}

export type BooleanField = BaseField<boolean> & {
  _type: 'boolean'
  type?: 'switch' | 'checkbox'
  label?: string
}

export type FileField = BaseField<FileValue> & {
  _type: 'file'
  label?: string
  accept: Array<'image' | 'pdf'>
}

type BaseNumberField = BaseField<number> & {
  _type: 'number'
  type?: 'input' | 'slider'
  precision?: number
  suffix?: string
  prefix?: string
  label?: string
}

type StandardNumberField = BaseNumberField & {
  _type: 'number'
  type?: 'input'
  min?: number
  max?: number
}

type SliderNumberField = BaseNumberField & {
  _type: 'number'
  type: 'slider'
  min: number
  max: number
  step: number
}

export type NumberField = StandardNumberField | SliderNumberField

export type ColorValue = {
  r: number
  g: number
  b: number
  a?: number
}

export type ColorField = BaseField<ColorValue> & {
  _type: 'color'
  value?: ColorValue
  defaultValue: ColorValue
  label?: string
  withAlpha?: boolean
}

export type RecordField = BaseField<ColorValue> & {
  _type: 'record'
  itemField: Field | FieldMap
  value?: Record<string, any>
  label?: ReactElement | string
}

export type DateField = BaseField<number> & {
  _type: 'date'
  label?: string
}

type AnyObject = { [key: string]: any }
export type Field =
  | TextField
  | FileField
  | BooleanField
  | SelectField
  | TextAreaField
  | NumberField
  | ColorField
  | RecordField

export type FieldType = Field['_type']
export type FieldMap = {
  name?: string
  validate?: (v: any) => AnyObject | undefined
  condition?: Condition
  children: { [key: string]: IFormElement }
}

export type IFormElement = Field | FieldMap
export const isField = (
  fieldOrFieldMap: IFormElement,
): fieldOrFieldMap is Field => !!(fieldOrFieldMap as Field)._type

// export type FieldMapValue<T extends FieldMap> = {
//   [K in keyof T['children']]: T['children'][K] extends Field
//     ? T['children'][K]['value']
//     : T['children'][K] extends FieldMap
//     ? FieldMapValue<T['children'][K]>
//     : never
// }
export type FieldMapValue = Record<string, any>

export type Formatter<T extends Field> = (value: T['value'], field: T) => string

export const isRecordField = (field: Field): field is RecordField => field._type === 'record'

export type UploadFileFunc = (baseStoragePath: string, fieldPath: string, child: FileField, data: FileValue | undefined) => Promise<FileValue | undefined>
