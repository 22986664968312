import { DoubleSide, ShaderMaterial, Texture } from 'three'

export function createShaderWithTextures(textures: Array<Texture>) {
  const uniforms: Record<string, any> = {}
  const textureUniformNames = []

  // Create a uniform for each texture.
  for (let i = 0; i < textures.length; i += 1) {
    const uniformName = `texture${i}`
    uniforms[uniformName] = { value: textures[i] }
    textureUniformNames.push(uniformName)
  }

  uniforms.progress = { value: 0.0 }

  // Now generate the fragment shader code dynamically.
  const fragmentShader = `
      precision highp float;

      varying vec2 vUv;
      ${textureUniformNames.map((name) => `uniform sampler2D ${name};`).join('\n')}
      uniform float progress;

      void main() {
          float totalTextures = ${textures.length}.0;
          float step = 1.0 / totalTextures;
          int texIndex = int(mod(floor(progress / step + vUv.x), totalTextures));
          float localProgress = (progress - float(texIndex) * step) / step;
          vec2 uv = vec2(localProgress, vUv.y);


          ${textureUniformNames.map((name, i) => `if(texIndex == ${i}) {
                      gl_FragColor = vec4(texture2D(${name}, vUv).xyz*0.72, 1.);
          }`).join(' else ')}
  
      }
  `

  const vertexShader = `
      precision highp float;

      varying vec2 vUv;

      void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
  `

  return new ShaderMaterial({
    uniforms,
    fragmentShader,
    vertexShader,
    side: DoubleSide,
  })
}
