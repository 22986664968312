import {
  AddIcon, ChevronDownIcon, ChevronUpIcon, DragHandleIcon, EditIcon,
} from '@chakra-ui/icons'
import {
  Box, Collapse, HStack, IconButton, Text, Tooltip, VStack,
} from '@chakra-ui/react'
import { capitalizeFirstLetter, FieldMap, WithId } from '@loneworld/shared'
import { FORM_ERROR, ValidationErrors } from 'final-form'
import { DocumentReference, updateDoc } from 'firebase/firestore'
import {
  FC, useCallback, useMemo, useState,
} from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggableProvidedDragHandleProps,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd'
import { deleteFilesWithPrefix } from '../../backend/functions'
import { DeleteButton, TooltipIconButton } from '../shared/Buttons'
import { Form } from './Form'
import { validateFieldMap } from './input/utils'

const ListItemEdit = <T extends {}>({
  value,
  onDelete,
  isDragging,
  onUpdate,
  baseStoragePath,
  field,
  expandedHeight,
  getBackgroundImageUrl,
  index,
  itemName,
  getTitle,
  Preview,
  dragHandleProps,
}: {
  value: WithId<T>
  baseStoragePath: string
  index: number
  isNew?: boolean
  onDelete: () => Promise<void>
  expandedHeight?: number
  itemName: string
  field: FieldMap
  isDragging: boolean
  onUpdate: (newValue: Partial<WithId<T>>) => Promise<ValidationErrors>
  getBackgroundImageUrl?: (v: WithId<T>) => string | undefined
  getTitle?: (v: WithId<T>) => string
  Preview: FC<{ value: WithId<T> }>
  dragHandleProps?: DraggableProvidedDragHandleProps | null
}) => {
  const [expanded, setExpanded] = useState(false)
  const [editing, setIsEditing] = useState(false)
  const height = useMemo(() => (expanded ? `${expandedHeight}px` : '160px'), [expanded, expandedHeight])
  const backgroundImage = useMemo(() => {
    if (!getBackgroundImageUrl) return undefined
    const url = getBackgroundImageUrl(value)
    return url ? `url(${url})` : undefined
  }, [value, getBackgroundImageUrl])
  const title = useMemo(
    () => (getTitle ? getTitle(value) : `${itemName} ${index + 1}`),
    [value, getTitle, itemName, index],
  )
  return (
    <Box
      border='1px solid #cdcdcd'
      borderRadius={6}
      bg='gray.500'
      userSelect='none'
      backgroundImage={backgroundImage}
      backgroundSize='cover'
      backgroundPosition='center'
      boxShadow={`1px 1px 4px ${isDragging ? '#00000033' : '#00000000'}`}
      w='100%'
    >
      <Collapse unmountOnExit in={!editing} style={{ width: '100%' }}>
        <VStack transition='height 300ms' height={height} align='flex-start' spacing={0} w='100%'>
          <HStack bg='blackAlpha.400' px={1} py={1} w='100%'>
            <Text pl={2} color='gray.50' fontFamily='Gloria Hallelujah' textShadow='1px 1px 3px black'>
              {title}
            </Text>
            <HStack spacing={0} ml='auto'>
              {expandedHeight ? (
                <TooltipIconButton
                  label={expanded ? 'COLLAPSE' : 'EXPAND'}
                  size='xs'
                  bg='transparent'
                  onClick={() => setExpanded(!expanded)}
                  icon={
                    <VStack spacing={0}>
                      <ChevronUpIcon
                        transition='transform 300ms'
                        transform={`translate(0, 4px) rotate(${expanded ? 180 : 0}deg)`}
                        width={4}
                        height={4}
                      />
                      <ChevronDownIcon
                        transition='transform 300ms'
                        transform={`translate(0, -4px)  rotate(${expanded ? -180 : 0}deg)`}
                        width={4}
                        height={4}
                      />
                    </VStack>
                  }
                />
              ) : null}

              <TooltipIconButton
                label='EDIT'
                onClick={() => setIsEditing(true)}
                size='xs'
                bg='transparent'
                icon={<EditIcon w={4} />}
              />
              {dragHandleProps ? (
                <TooltipIconButton
                  label='DRAG'
                  bg='transparent'
                  size='xs'
                  icon={<DragHandleIcon w={3} />}
                  {...dragHandleProps}
                />
              ) : null}
            </HStack>
          </HStack>
          <Preview value={value} />
          <HStack mt='auto' justify='flex-end' pb={2} w='100%'>
            <DeleteButton size='xs' itemName={itemName} shouldConfirm onClick={onDelete} />
          </HStack>
        </VStack>
      </Collapse>
      <Collapse unmountOnExit in={!!editing} style={{ width: '100%' }}>
        <Box bg='blackAlpha.700'>
          <Form
            baseStoragePath={baseStoragePath}
            onCancel={() => setIsEditing(false)}
            field={field}
            onSubmit={(v) => onUpdate({ ...v, _id: value._id } as Partial<WithId<T>>).then((res) => {
              if (res) return res
              setIsEditing(false)
              return undefined
            })}
            value={value}
          />
        </Box>
      </Collapse>
    </Box>
  )
}

type ListEditProps<T extends {}> = {
  value?: Array<WithId<T>> | Record<string, Array<WithId<T>> | undefined>
  itemField: FieldMap
  documentRef: DocumentReference
  ItemPreview: FC<{ value: WithId<T> }>
  expandedItemHeight?: number
  baseStoragePath: string
  getItemTitle?: (v: WithId<T>) => string
  getItemBackgroundUrl?: (v: WithId<T>) => string | undefined
  itemName: string
  listName: string
  propertyPath: string | string[]
}

const InnerListEdit = <T extends {}>({
  value,
  propertyPath,
  documentRef,
  itemField,
  expandedItemHeight,
  baseStoragePath,
  ItemPreview,
  getItemTitle,
  getItemBackgroundUrl,
  itemName,
  title,
}: Omit<ListEditProps<T>, 'propertyPath' | 'value' | 'listName'> & {
  propertyPath: string
  value: Array<WithId<T>>
  baseStoragePath: string
  title: string
}) => {
  const [creatingNew, setCreatingNew] = useState<boolean>(false)

  const newItemId = useMemo(() => (creatingNew ? `${Date.now()}` : undefined), [creatingNew])
  const handleUpdateItem = useCallback(
    async (index: number, newValue: Partial<WithId<T>>) => {
      const validate = itemField.validate || validateFieldMap
      const validationErrors = validate(itemField, newValue)
      if (validationErrors) return validationErrors
      const updatedArr = value ? [...value] : []
      updatedArr[index] = { ...(newValue as T), _id: newValue._id || `${Date.now()}` }
      try {
        await updateDoc(documentRef, {
          [propertyPath]: updatedArr,
        })
        return undefined
      } catch (e: any) {
        console.error(e)
        return { [FORM_ERROR]: e.message }
      }
    },
    [propertyPath, value, itemField, documentRef],
  )
  const handleDeleteItem = useCallback(
    async (index: number) => {
      const deletedItemId = value[index]._id
      const updatedArr = value ? [...value] : []
      updatedArr.splice(index, 1)
      if (deletedItemId) await deleteFilesWithPrefix({ prefix: `${baseStoragePath}/${propertyPath}/${deletedItemId}` })
      await updateDoc(documentRef, propertyPath, updatedArr)
    },
    [propertyPath, value, documentRef, baseStoragePath],
  )
  return (
    <VStack flex={1} bg='gray.300' spacing={0} borderRadius={6}>
      <HStack w='100%' borderBottom='1px solid #777'>
        <Text fontFamily='Gloria Hallelujah' px={3} pb={1} pt={2} color='gray.600' fontWeight='bold'>
          {title}
        </Text>
        <HStack ml='auto'>
          <Tooltip placement='top' hasArrow label={`CREATE ${itemName.toUpperCase()}`}>
            <IconButton
              color='gray.600'
              onClick={() => setCreatingNew(true)}
              size='sm'
              variant='ghost'
              aria-label='add'
              icon={<AddIcon />}
            />
          </Tooltip>
        </HStack>
      </HStack>
      <Collapse style={{ width: '100%' }} in={!creatingNew}>
        <Droppable droppableId={propertyPath}>
          {(provided, snapshot) => (
            <VStack
              p={2}
              boxShadow={`1px 1px ${snapshot.isDraggingOver ? 4 : 2}px 0px rgba(0,0,0,0.2)`}
              ref={provided.innerRef}
              w='100%'
            >
              {value?.length ? (
                value.map((item, index) => (
                  <Draggable key={item._id} draggableId={item._id} index={index}>
                    {(draggableProvided, draggableSnapshot) => (
                      <Box width='100%' ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                        <ListItemEdit
                          Preview={ItemPreview}
                          itemName={itemName}
                          field={itemField}
                          expandedHeight={expandedItemHeight}
                          getTitle={getItemTitle}
                          getBackgroundImageUrl={getItemBackgroundUrl}
                          index={index}
                          baseStoragePath={`${baseStoragePath}/${propertyPath}/${item._id}`}
                          onDelete={() => handleDeleteItem(index)}
                          dragHandleProps={draggableProvided.dragHandleProps}
                          onUpdate={async (newValue) => handleUpdateItem(index, newValue)}
                          isDragging={draggableSnapshot.isDragging}
                          value={item}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))
              ) : (
                <Text p={2} fontSize='md' color='gray.500'>
                  {snapshot.isDraggingOver ? '' : `No ${itemName}s`}
                </Text>
              )}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </Collapse>
      <Collapse style={{ width: '100%' }} unmountOnExit in={!!creatingNew}>
        <Box bg='gray.500'>
          <Form
            baseStoragePath={`${baseStoragePath}/${propertyPath}/${newItemId}`}
            field={itemField}
            onCancel={() => setCreatingNew(false)}
            onSubmit={async (newVal) => {
              const validate = itemField.validate || validateFieldMap
              const validationErrors = validate(itemField, newVal)
              if (validationErrors) return validationErrors

              const updatedArr = value ? [...value] : []
              updatedArr.push({ ...newVal, _id: newItemId } as WithId<T>)
              await updateDoc(documentRef, {
                [propertyPath]: updatedArr,
              })
              setCreatingNew(false)
              return undefined
            }}
          />
        </Box>
      </Collapse>
    </VStack>
  )
}

export const ListEdit = <T extends {}>({
  propertyPath,
  baseStoragePath,
  value: _value,
  listName,
  documentRef,
  ...props
}: ListEditProps<T>) => {
  const valueIsArray = useMemo(() => !Array.isArray(propertyPath), [propertyPath])
  const value = useMemo(
    () => (valueIsArray ? (_value as Array<WithId<T>>) : (_value as Record<string, Array<WithId<T>>> | undefined)),
    [_value, valueIsArray],
  )
  const paths = useMemo(() => (Array.isArray(propertyPath) ? propertyPath : [propertyPath]), [propertyPath])
  const move = useCallback(
    (
      source: Array<WithId<T>>,
      destination: Array<WithId<T>>,
      droppableSource: DraggableLocation,
      droppableDestination: DraggableLocation,
    ) => {
      const sourceClone = Array.from(source)
      const destClone = Array.from(destination)
      const [removed] = sourceClone.splice(droppableSource.index, 1)

      destClone.splice(droppableDestination.index, 0, removed)

      const result: Partial<Record<string, Array<WithId<T>>>> = {}
      result[droppableSource.droppableId] = sourceClone
      result[droppableDestination.droppableId] = destClone

      return result
    },
    [],
  )

  const reorder = useCallback((list: Array<WithId<T>>, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }, [])

  const handleDragEnd: OnDragEndResponder = useCallback(
    async (result) => {
      const { source, destination } = result
      // dropped outside the list
      if (!destination) return
      if (!source) return

      if (source.droppableId === destination.droppableId) {
        const list = Array.isArray(value) ? value : (value as Record<string, Array<WithId<T>>>)?.[source.droppableId]
        if (!list) return
        const items = reorder(list, source.index, destination.index)

        await updateDoc(documentRef, {
          [source.droppableId]: items,
        })
      } else {
        const sourceList = Array.isArray(value) ? value : value?.[source.droppableId]
        const destinationList = Array.isArray(value) ? value : value?.[destination.droppableId]
        if (!sourceList || !destinationList) return
        const moved = move(sourceList, destinationList, source, destination)
        await updateDoc(documentRef, moved)
      }
    },
    [value, move, reorder, documentRef],
  )
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <HStack align='flex-start' p={4} w='100%'>
        {paths.map((p, idx) => (
          <InnerListEdit
            key={p}
            {...props}
            baseStoragePath={baseStoragePath}
            documentRef={documentRef}
            value={valueIsArray ? (value as Array<WithId<T>>) : (value as Record<string, Array<WithId<T>>>)?.[p]}
            title={paths.length > 1 ? `${capitalizeFirstLetter(listName)} ${idx + 1}` : capitalizeFirstLetter(listName)}
            propertyPath={p}
          />
        ))}
      </HStack>
    </DragDropContext>
  )
}
