import { CityView } from '@loneworld/shared'
import { Vector3Tuple } from 'three'

export type CameraLocation = {
  _name: string
  position: Vector3Tuple
  lookAt?: Vector3Tuple
  rotation?: Vector3Tuple
}

export const index: CameraLocation = {
  _name: 'index',
  position: [2.6, 2.8, -2.5],
  lookAt: [2.6, 2.8, -2.49],
}

export const comingSoon: CameraLocation = {
  _name: 'coming-soon',
  lookAt: [-13.959999999999999, 2.8, -2.49],
  position: [-13.959999999999999, 2.8, -2.5],
}

export const busstop: CameraLocation = {
  _name: 'busstop',
  lookAt: [-29.369999999999997, 2.8, -2.49],
  position: [-29.369999999999997, 2.8, -2.5],
}

export const contact: CameraLocation = {
  _name: 'contact',
  lookAt: [-22.47, 2.8, -2.49],
  position: [-22.47, 2.8, -2.5],
}

export const shop: CameraLocation = {
  _name: 'shop',
  lookAt: [-43.3906, 2.8, 3.81],
  position: [-43.4, 2.8, 3.8],
  // rotation: [Math.PI, -0.7539822368615503, Math.PI],
}

export const locations: Record<CityView, CameraLocation> = {
  index,
  'coming-soon': comingSoon,
  contact,
  busstop,
  shop,
}
