import { useThree } from '@react-three/fiber'
import { FC, useEffect } from 'react'
import { OrthographicCamera } from 'three'

export const isSSR = typeof window === 'undefined'
export const ConfigureGL: FC = () => {
  const { gl, size, camera } = useThree()
  useEffect(() => {
    // gl.setPixelRatio(window.devicePixelRatio)
    gl.setSize(window.innerWidth, window.innerHeight)
    // gl.shadowMap.enabled = true;
    // gl.outputEncoding = sRGBEncoding;
    if (camera instanceof OrthographicCamera) {
      camera.left = !isSSR ? window.innerWidth / -2 : 0
      camera.right = !isSSR ? window.innerWidth / 2 : 0
      camera.top = !isSSR ? window.innerHeight / 2 : 0
      camera.bottom = !isSSR ? window.innerHeight / -2 : 0
    }
    // gl.capabilities.precision.
  }, [size, camera, gl])
  return null
}
