import { Poster, TheaterConfig } from '@loneworld/shared'
import { Plane, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import {
  useContext, useEffect, useMemo, useState,
} from 'react'
import {
  Mesh, Vector2, Vector2Tuple, Vector3Tuple,
} from 'three'
import { usePointerHover } from '../../../../../hooks/usePointerHover'
import { RippleShader } from '../../../../shared/FadeImageTexture'
import { TheaterContext } from './context'
import { usePosterUrl } from './hooks'
import { buttonMaterial } from './Playlist'

const posterOrientations: Array<Vector3Tuple> = [
  [-11.22, 8.1, 7.55],
  [-12.06, 7.26, 0.9],
  [-12.72, 6.3, -5.5],
]

const posterScale = [5.7, 5.7, 5.7] as Vector3Tuple
const posterRotation = [0, Math.PI * 0.5375, 0] as Vector3Tuple
const altPosterRotation = [0, Math.PI * 0.5375, 0] as Vector3Tuple

const fallbackUv = new Vector2(0.5, 0.5)

const buttonScale = [0.2, 0.2, 0.2] as Vector3Tuple
const buttonPosition = [0, -1, 0] as Vector3Tuple
const posterArgs: Vector2Tuple = [1, 1.3]
const MoviePoster = ({
  posters,
  position,
  rotation,
  onBlur,
  onHover,
  scale,
  loadMsOffset,
}: {
  posters: Array<Poster> | undefined
  onHover: () => void
  onBlur: () => void
  position: Vector3Tuple
  rotation: Vector3Tuple
  scale: Vector3Tuple
  loadMsOffset: number
}) => {
  const { poster, onClick, index } = usePosterUrl(loadMsOffset, posters)
  const {
    playlist: {
      onSelectId,
    },
  } = useContext(TheaterContext)
  const { image } = poster || {}
  const { posterUrl, url: _url } = image || {}
  const url = useMemo(() => posterUrl || _url, [posterUrl, _url])

  const [rippleShader] = useState(new RippleShader(url))

  const {
    nodes: { PLAY },
  } = useGLTF('/assets/3d/theater/play.glb') as unknown as {
    nodes: Record<string, Mesh>
  }

  useEffect(() => {
    if (url) rippleShader.updateTexture(url, fallbackUv)
  }, [url, rippleShader])

  useFrame((_, dT) => {
    rippleShader.update(dT * 0.6)
  }, 1)

  const hoverProps = usePointerHover(onHover, onBlur)

  return (
    <group position={position} rotation={rotation} scale={scale}>
      <Plane
        material={rippleShader.material}
        {...hoverProps}
        onClick={(e) => {
          const nextUrl = posters?.[(index + 1) % posters.length]?.image?.url

          if (nextUrl && nextUrl !== url) {
            rippleShader.updateTexture(nextUrl, e.uv || fallbackUv)
            onClick()
          }
          e.stopPropagation()
        }}
        args={posterArgs}
      />
      <mesh
        {...hoverProps}
        onClick={() => {
          if (poster?.videoId) onSelectId(poster.videoId)
        }}
        scale={buttonScale}
        material={buttonMaterial}
        position={buttonPosition}
        geometry={PLAY.geometry}
      />
    </group>
  )
}

const frameOffset = 8000 / 3
export const MoviePosters = ({
  theaterConfig,
  onPosterHover,
  onPosterBlur,
}: {
  theaterConfig: TheaterConfig | null
  onPosterHover: (index: number) => void
  onPosterBlur: () => void
}) => {
  const { poster1, poster2, poster3 } = theaterConfig || {}
  const posters = useMemo(() => [poster1, poster2, poster3], [poster1, poster2, poster3])
  return (
    <>
      {posterOrientations.map((pos, index) => (
        <MoviePoster
          position={pos}
          scale={posterScale}
          loadMsOffset={index * frameOffset}
          posters={posters[index]}
          onBlur={onPosterBlur}
          onHover={() => onPosterHover(index)}
          rotation={index === 2 ? altPosterRotation : posterRotation}
          key={index}
        />
      ))}
    </>
  )
}
