import { TextAreaField } from '@loneworld/shared'
import { forwardRef, useRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { useInputImperativeHandle } from './hooks'
import { InputProps, InputRef } from './types'

export const TextAreaComponent = forwardRef<
  InputRef,
  InputProps<TextAreaField>
>(({ input, field }, ref) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useInputImperativeHandle(ref, () => inputRef.current?.focus(), () => inputRef.current?.blur())

  return (
    <TextareaAutosize
      ref={inputRef}
      placeholder={field.placeholder}
      minRows={2}
      maxRows={5}
      style={{
        background: 'none',
        color: 'white',
        // height: 'auto',
        resize: 'none',
        width: '100%',
        padding: '0.5rem 1rem',
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontFamily: 'Encode Sans',
        border: 'none',
        outline: 'none',
      }}
      {...input}
    />
  )
})
