/* eslint-disable no-await-in-loop */
import { UploadFileFunc } from '../types/files'
import {
  Field, FieldMap, isField, RecordField,
} from '../types/forms'
import { rgbToHex } from './colors'

export const getFieldName = (field: FieldMap | Field) => (isField(field) ? field.placeholder : field.name)

// formatters for each field type
export const formatField: {
  [K in Field['_type']]: (
    value: Extract<Field, { _type: K }>['value'],
    field: Extract<Field, { _type: K }>
  ) => string
} = {
  boolean: (value, field) => (value ? 'Yes' : 'No'),
  text: (value, field) => value || '',
  file: (value, field) => (value ? value.title || '' : ''),
  color: (value, field) => (value ? rgbToHex(value.r, value.g, value.b) : ''),
  number: (value, field) => (value ? value.toString() : ''),
  select: (value, field) => (value ? value.toString() : ''),
  textarea: (value, field) => (value ? value.toString() : ''),
  record: (v, field) => `${Object.keys(v || {}).length} items`,
}

const recordFieldToFormData = (field: RecordField, data?: Array<any>) => data?.map((item) => (isField(field.itemField) ? { value: item } : item))
const fieldToFormData = (field: Field, data?: any) => (
  field._type === 'record' ? recordFieldToFormData(field, data) : data
)

export const processField = async (baseStoragePath: string, fieldPath: string, field: Field, uploadFile: UploadFileFunc, data?: any) => {
  const asNum = parseFloat(data)
  switch (field._type) {
    case 'file':
      return uploadFile(baseStoragePath, fieldPath, field, data)
    case 'boolean':
      return data || false
    case 'number':
      if (Number.isNaN(asNum)) return undefined
      return asNum

    case 'record':
      return data || []
    default:
      return data || ''
  }
}

export const processData = async (
  baseStoragePath: string,
  field: FieldMap,
  uploadFile: UploadFileFunc,
  data?: any,
  fieldPath: string = '',
): Promise<Record<string, any>> => {
  const acc: Record<string, any> = {}
  const entries = Object.entries(field.children)
  const numChildren = entries.length
  for (let i = 0; i < numChildren; i += 1) {
    const [fieldKey, child] = entries[i]
    const childPath = fieldPath ? `${fieldPath}.${fieldKey}` : fieldKey
    if (isField(child)) {
      acc[fieldKey] = await processField(baseStoragePath, childPath, child, uploadFile, data?.[fieldKey])
    } else {
      // THIS MIGHT BE BREAKING THINGS
      acc[fieldKey] = await processData(baseStoragePath, child, uploadFile, data?.[fieldKey], childPath)
    }
  }
  return acc
}

export const toFormData = (field: FieldMap, data?: Record<string, any>) => Object.entries(field.children).reduce(
  (acc, [fieldId, child]) => ({
    ...acc,
    [fieldId]: isField(child)
      ? fieldToFormData(child, data?.[fieldId])
      : toFormData(child, data?.[fieldId]),
  }),
  {} as Record<string, any>,
)
