import { NumberField } from '@loneworld/shared'
import { forwardRef, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useInputImperativeHandle, useInputStyle } from '../hooks'
import { InputSlider } from '../InputSlider'
import { InputProps, InputRef } from '../types'
import './styles.css'

export const NumberComponent = forwardRef<InputRef, InputProps<NumberField>>(({
  input: { onChange, value, ...input }, field, size, ...props
}, ref) => {
  const { type, defaultValue, placeholder } = field
  const style = useInputStyle(size)
  const inputRef = useInputImperativeHandle(ref)
  const [endsWith, setEndsWith] = useState('')
  const displayed = typeof value === 'number' && !Number.isNaN(value) ? `${value}${endsWith}` : ''
  if (type === 'slider') {
    return (
      <InputSlider
        label={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      />
    )
  }
  return (
    <CurrencyInput
      className='number-input'
      placeholder={field.placeholder}
      decimalsLimit={field.precision}
      allowDecimals={!!field.precision}
      ref={inputRef}
      onValueChange={(v, _, values) => {
        if (v?.endsWith('.')) setEndsWith('.')
        else if (v?.includes('.') && v?.endsWith('0')) setEndsWith('0')
        else setEndsWith('')
        onChange(values?.float)
      }}
      value={displayed}
      {...props}
      style={{
        background: 'none',
        color: 'white',
        // height: 'auto',
        resize: 'none',
        fontFamily: 'Encode Sans',
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: '4px',
        border: 'none',
        outline: 'none',
        ...style,
        ...props.style,
      }}
      {...input}
    />
  )
})
