import { GestureController, GestureToController, ScrollSnapGesture } from '../../types'

const getInitialIndex = (bit: ScrollSnapGesture, initValue?: number) => {
  let res = 0
  if (!initValue) return 0
  for (let i = 0; i < bit.targets.length; i += 1) {
    if (initValue <= bit.targets[i]) return res
    res += 1
  }
  return res
}
export const getScrollSnapGesture: GestureToController<ScrollSnapGesture> = (
  bit: ScrollSnapGesture,
): GestureController => ({
  subscribe: ({
    onChange, throttle, initialValue,
  }) => {
    const { targets, cycle } = bit
    let currIdx = getInitialIndex(bit, initialValue)
    let lastEvent = 0

    // dont want event to fire for smooth scrolling
    const handleWheel = (e: WheelEvent) => throttle(() => {
      // filter out everything but initial gesture
      if (e.timeStamp - lastEvent < 1200) return
      const shouldGoForward = e.deltaY > 0
      let targetIdx: number | undefined
      if (shouldGoForward) {
        if (currIdx >= targets.length - 1) {
          if (cycle) targetIdx = 0
        } else {
          targetIdx = currIdx + 1
        }
      } else if (currIdx <= 0) {
        if (cycle) targetIdx = targets.length - 1
      } else {
        targetIdx = currIdx - 1
      }
      if (targetIdx !== undefined) {
        lastEvent = e.timeStamp
        onChange(targets[targetIdx])
        currIdx = targetIdx
        targetIdx = undefined
      }
    })
    window.addEventListener('wheel', handleWheel)
    return () => {
      window.removeEventListener('wheel', handleWheel)
    }
  },
})
