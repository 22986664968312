export const vertexShaderSource = `
varying vec2 vUv;
void main() {
  vUv = uv;
  gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
}`

export const fragmentShaderSource = /* glsl */`
uniform sampler2D texture0;
uniform sampler2D texture1;
uniform int currentTexture;
uniform vec2 fadeCenter;
uniform float time;
uniform float fadeStartTime;
uniform float loadingStartTime;
uniform float loadingEndTime;

varying vec2 vUv;

void main() {
  vec2 texCoord = vUv;
  vec2 shadowCoord = vUv + vec2(0.05, 0.05);
 
  float timeSinceStart = (time - fadeStartTime)*3.0;
  float timeSinceLoadingStart = (time - loadingStartTime)*3.5;
  float moddedTimeSinceLoadingStart = mod(timeSinceLoadingStart, 2.5);
  // float timeSinceStart = (time - fadeStartTime)*0.25;
  float dist = distance(texCoord, fadeCenter);
  float shadowDist = distance(shadowCoord, fadeCenter);
  float fadeFactor = 1.0;
  if(fadeStartTime > 0.0) {
    fadeFactor = smoothstep(0.0, 1.0, pow(max(0.0, timeSinceStart - dist), 0.8));
    // float fadeFactor = 0.0;
  }
  float fadeAmount = smoothstep(0.0, 1.0, distance(texCoord, fadeCenter));

  float rippleRadius = pow(timeSinceStart, 0.8) * 0.77;
  float rippleOpacity = max(0.0, 1.0 - timeSinceStart);

  float loadingRippleRadius = pow(moddedTimeSinceLoadingStart, 0.8) * 0.77;
  float loadingRippleOpacity = max(0.0, 1.0 - moddedTimeSinceLoadingStart);
  if(loadingEndTime >= loadingStartTime) {
    loadingRippleOpacity = max(0.0, 1.0 - (time - loadingEndTime)*2.5);
  }

  float ripple1Mask = smoothstep(0.0, 1.0, 1.0 - 8.0 * abs(dist - rippleRadius));
  float shadowMask = smoothstep(0.0, 1.0, 1.0 - 8.0 * abs(shadowDist - rippleRadius));

  float loadingRippleMask = smoothstep(0.0, 1.0, 1.0 - 15.0 * abs(dist - loadingRippleRadius));
  float loadingShadowMask = smoothstep(0.0, 1.0, 1.0 - 15.0 * abs(shadowDist - loadingRippleRadius));

  // float ripple = (ripple1Mask - shadowMask)*0.5;
  float ripple = max((ripple1Mask - shadowMask)*0.5, (loadingRippleMask - loadingShadowMask)*0.5*loadingRippleOpacity);
  // Fade between textures
  if(currentTexture == 1) {
  vec4 color1 = texture2D(texture0, texCoord);
  vec4 color2 = texture2D(texture1, texCoord);
  csm_DiffuseColor = mix(color1, color2, fadeFactor) + vec4(ripple, ripple, ripple, rippleOpacity);
  } else {
  vec4 color1 = texture2D(texture1, texCoord);
  vec4 color2 = texture2D(texture0, texCoord);
  csm_DiffuseColor = mix(color1, color2, fadeFactor) + vec4(ripple, ripple, ripple, rippleOpacity);
  }
}`
