import {
  Center, CircularProgress, Fade, Image, Text,
} from '@chakra-ui/react'
import { config } from '@react-spring/core'
import { a, useSpring } from '@react-spring/three'
import {
  Box, Html, Text as DreiText, useGLTF,
} from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Color, Group, Mesh, MeshPhongMaterial,
} from 'three'
import { useMounted } from '../../../../../hooks/useMounted'
import { TheaterContext } from './context'
import { PlaylistItem } from './types'

const aspect = 16 / 9
const itemHeight = 0.45
const itemWidth = itemHeight * aspect

export const buttonMaterial = new MeshPhongMaterial({ color: 0xefefef })

const PlaylistItemUI = ({
  item,
  loading,
  onClick,
  visible,
  onEnter,
  onLeave,
}: {
  item: PlaylistItem | null
  visible: boolean
  loading: boolean
  onEnter: () => void
  onClick: () => void
  onLeave: () => void
  back?: boolean
}) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const thumbnail = item?.snippet?.thumbnails?.medium
  const ref = useRef<HTMLImageElement>(null)
  useEffect(() => {
    if (!ref.current) return () => {}
    const loaded = ref.current.complete
    setImageLoaded(loaded)
    if (!loaded) {
      const el = ref.current
      const onLoad = () => setImageLoaded(true)
      el.addEventListener('load', onLoad)
      return () => {
        el.removeEventListener('load', onLoad)
      }
    }
    return () => {}
  }, [thumbnail])
  return (
    <Center
      onPointerEnter={onEnter}
      onPointerLeave={onLeave}
      cursor='pointer'
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      // opacity={visible ? 1 : 0}
      userSelect='none'
      onPointerDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      pointerEvents={visible ? 'auto' : 'none'}
      position='relative'
      // transform={`rotateY(${back ? 180 : 0}deg)`}
      // transition={`opacity 330ms ease ${visible ? 300 : 0}ms`}
      width={`${150 * aspect}px`}
      height={`${150}px`}
      style={{ backfaceVisibility: 'hidden' }}
    >
      <Fade in={thumbnail && imageLoaded}>
        <Image ref={ref} height='100%' src={thumbnail?.url || ''} />
      </Fade>
      <Center position='absolute' left={0} top={0} w='100%' height='100%'>
        <Fade in={loading && visible}>
          <CircularProgress
            opacity={0.5}
            color='gray'
            size='90px'
            isIndeterminate
          />
        </Fade>
      </Center>
      <Center position='absolute' left={0} top={0} w='100%' height='100%'>
        <Fade in={!loading && !thumbnail}>
          <Text
            color='white'
            fontSize='92px'
            lineHeight={1}
            textAlign='center'
            textShadow='0 0 12px black'
          >
            {item ? '?' : ''}
          </Text>
        </Fade>
      </Center>
    </Center>
  )
}

const PlaylistItemView = ({
  index,
  pageIndex,
  loading,
  item,
  onEnter,
  mounted,
  onClick,
  onLeave,
}: {
  index: number
  loading: boolean
  mounted: boolean
  pageIndex: number
  onEnter: () => void
  onClick: () => void
  onLeave: () => void
  item: PlaylistItem | null
}) => {
  const {
    playlist: { selectedIndex },
  } = useContext(TheaterContext)
  const [frontSide, setFrontSide] = useState<PlaylistItem | null>(null)
  const [backSide, setBackSide] = useState<PlaylistItem | null>(null)

  const ref = useRef<Mesh>(null)
  useEffect(() => {
    if (pageIndex % 2 === 0) {
      setFrontSide(item ? { ...item } : null)
      // setBackSide(null)
    } else {
      setBackSide(item ? { ...item } : null)
      // setFrontSide(null)
    }
  }, [pageIndex, item])
  const {
    x, y, row, column,
  } = useMemo(() => {
    const c = index % 5
    const r = Math.floor(index / 5)
    return {
      x: -(itemHeight + 0.05) * (5 / 1.47) + c * (itemWidth + 0.05),
      y: (itemHeight + 0.05) * (5 / 2.4) - (r + 0.5) * (itemHeight + 0.05),
      row: r,
      column: c,
    }
  }, [index])

  const { r } = useSpring({
    r: Math.PI * pageIndex,
    config: config.slow,
    delay: (Math.abs(2 - row) + Math.abs(2 - column)) * 100,
  })
  return (
    <a.group
      position={[x, y, 0.05]}
      // @ts-ignore
      rotation={r.to((rot) => [0, rot, 0])}
    >
      <Box ref={ref} scale={[itemWidth, itemHeight, 0.1]}>
        <meshPhongMaterial color={0xab7777} />
      </Box>
      <Html
        occlude={[ref]}
        scale={[0.11, 0.11, 0.11]}
        style={{
          height: '150px',
          width: `${150 * aspect}px`,
          opacity: mounted ? 1 : 0,
          transition: 'opacity 500ms',
        }}
        position={[0, 0, 0.06]}
        center
        transform
      >
        <PlaylistItemUI
          onEnter={onEnter}
          onLeave={onLeave}
          onClick={onClick}
          visible={!selectedIndex && pageIndex % 2 === 0}
          loading={loading}
          item={frontSide}
        />
      </Html>
      <Html
        occlude={[ref]}
        scale={[0.11, 0.11, 0.11]}
        style={{
          height: '150px',
          width: `${150 * aspect}px`,
          opacity: mounted ? 1 : 0,
          transition: 'opacity 500ms',
        }}
        position={[0, 0, -0.06]}
        rotation={[0, Math.PI, 0]}
        center
        transform
      >
        <PlaylistItemUI
          onEnter={onEnter}
          onLeave={onLeave}
          onClick={onClick}
          visible={!selectedIndex && pageIndex % 2 === 1}
          loading={loading}
          back
          item={backSide}
        />
      </Html>
    </a.group>
  )
}

const AText = a(DreiText)

const PlaylistControls = () => {
  const {
    playlist: {
      goNext,
      goPrev,
      goPrevVideo,
      goNextVideo,
      onSelect,
      selectedIndex,
      playerVisible,
      numPages,
      currentPage,
    },
    player: {
      player, playerState, onVolumePress, muted,
    },
  } = useContext(TheaterContext)

  const {
    nodes: { PLAY },
  } = useGLTF('/assets/3d/theater/play.glb') as unknown as {
    nodes: Record<string, Mesh>
  }

  const {
    nodes: { PAUSE1, PAUSE2 },
  } = useGLTF('/assets/3d/theater/pause.glb') as unknown as {
    nodes: Record<string, Mesh>
  }
  const {
    nodes: { Cube: PREV },
  } = useGLTF('/assets/3d/theater/prev.glb') as unknown as {
    nodes: Record<string, Mesh>
  }
  const {
    nodes: { Cube: SKIP },
  } = useGLTF('/assets/3d/theater/skip.glb') as unknown as {
    nodes: Record<string, Mesh>
  }

  const {
    nodes: { GRID },
  } = useGLTF('/assets/3d/theater/grid.glb') as unknown as {
    nodes: Record<string, Mesh>
  }
  const { playScale } = useSpring({
    playScale: playerVisible && playerState !== 1 ? 0.15 : 0,
  })
  const { pauseScale } = useSpring({
    pauseScale: playerVisible && playerState === 1 ? 0.18 : 0,
  })
  const { videoControlScale, skipButtonScale } = useSpring({
    videoControlScale: playerVisible ? 0.12 : 0,
    skipButtonScale: selectedIndex !== null ? 0.12 : 0,
  })

  const { playlistControlScale } = useSpring({
    playlistControlScale: !playerVisible ? 0.12 : 0,
  })

  const {
    nodes: { VOLUME, WAVES },
  } = useGLTF('/assets/3d/theater/volume.glb') as unknown as {
    nodes: Record<string, Mesh>
  }
  const { wavesControlScale } = useSpring({
    wavesControlScale: playerVisible && !muted ? 0.12 : 0,
  })

  const { domElement } = useThree((s) => s.gl)

  const onPointerEnter = useCallback(() => {
    if (domElement) {
      domElement.style.cursor = 'pointer'
    }
  }, [domElement])

  const onPointerLeave = useCallback(() => {
    if (domElement) {
      domElement.style.cursor = 'default'
    }
  }, [domElement])

  const hoverEvents = useMemo(
    () => ({
      onPointerEnter,
      onPointerLeave,
    }),
    [onPointerEnter, onPointerLeave],
  )

  return (
    <group position={[0, -1.8, 0]}>
      {/* <Box scale={[2, 0.4, 0.1]}>
        <meshPhongMaterial color={0xcdcdcd} />
      </Box> */}
      <a.mesh
        position={[0, 0, 0.15]}
        scale={playScale.to((s) => [s, s, s])}
        onClick={() => {
          if (player) {
            player.playVideo()
          }
        }}
        geometry={PLAY.geometry}
        material={PLAY.material}
        {...hoverEvents}
      />
      <a.group scale={pauseScale.to((s) => [s, s, s])}>
        <Box
          onClick={() => {
            if (!player) return
            player.pauseVideo()
          }}
          scale={[1.1, 1.1, 1.1]}
          {...hoverEvents}
        >
          <meshBasicMaterial transparent opacity={0} />
        </Box>
        <mesh
          position={[-0.4, 0, 0.15]}
          geometry={PAUSE1.geometry}
          material={PAUSE1.material}
          {...hoverEvents}
        />
        <mesh
          position={[0.4, 0, 0.15]}
          geometry={PAUSE2.geometry}
          material={PAUSE2.material}
          {...hoverEvents}
        />
      </a.group>
      <a.mesh
        scale={skipButtonScale.to((s) => [s, s, s])}
        onClick={goNextVideo}
        position={[0.75, 0, 0.12]}
        // rotation={[0, 0, Math.PI]}
        geometry={SKIP.geometry}
        material={buttonMaterial}
        {...hoverEvents}
      />
      <a.mesh
        onClick={goPrevVideo}
        scale={skipButtonScale.to((s) => [s, s, s])}
        position={[-0.75, 0, 0.12]}
        rotation={[0, 0, Math.PI]}
        geometry={PREV.geometry}
        material={buttonMaterial}
        {...hoverEvents}
      />
      <a.group
        position={[-2, 0, 0.12]}
        scale={videoControlScale.to((s) => [s * 2.5, s * 2.5, s * 2.5])}
      >
        <Box onClick={() => onSelect(null)} scale={[1.1, 1.1, 1.1]}>
          <meshBasicMaterial transparent opacity={0} />
        </Box>
        <mesh
          {...hoverEvents}
          onClick={() => onSelect(null)}
          rotation={[Math.PI / 2, 0, Math.PI]}
          geometry={GRID.geometry}
          material={buttonMaterial}
        />
      </a.group>
      <group position={[2, 0, 0.12]}>
        <a.mesh
          {...hoverEvents}
          scale={videoControlScale.to((s) => [s * 2, s * 2, s * 2])}
          onClick={() => {
            if (!player) return
            if (player.isMuted()) {
              player.unMute()
              onVolumePress(false)
            } else {
              player.mute()
              onVolumePress(true)
            }
          }}
        >
          <boxGeometry />
          <meshBasicMaterial transparent opacity={0} />
        </a.mesh>
        <a.mesh
          {...hoverEvents}
          scale={videoControlScale.to((s) => [s * 1.4, s * 1.4, s * 1.4])}
          // rotation={[Math.PI / 2, 0, Math.PI]}
          geometry={VOLUME.geometry}
          material={buttonMaterial}
        />
        <a.mesh
          {...hoverEvents}
          scale={wavesControlScale.to((s) => [s * 1.4, s * 1.4, s * 1.4])}
          // rotation={[Math.PI / 2, 0, Math.PI]}
          geometry={WAVES.geometry}
          material={buttonMaterial}
        />
      </group>

      <a.mesh
        {...hoverEvents}
        scale={playlistControlScale.to((s) => [s, s, s])}
        position={[0.7, 0, 0.15]}
        onClick={goNext}
        geometry={PLAY.geometry}
        material={PLAY.material}
      />
      <AText
        position={[0, -0.05, 0]}
        scale={playlistControlScale.to((s) => [s * 1.4, s * 1.4, s * 1.4])}
      >
        {Number.isNaN(currentPage) ? '' : `${currentPage}/${numPages}`}
      </AText>
      <a.mesh
        {...hoverEvents}
        scale={playlistControlScale.to((s) => [s, s, s])}
        position={[-0.7, 0, 0.15]}
        rotation={[0, 0, Math.PI]}
        onClick={goPrev}
        geometry={PLAY.geometry}
        material={PLAY.material}
      />
    </group>
  )
}

const textColor = new Color(0xffffff)

export const Playlist = () => {
  const {
    playlist: {
      items,
      pageIndex,
      isFetching,
      hovered,
      onHover,
      onSelect,
      selected,
    },
  } = useContext(TheaterContext)
  const slots = useMemo(() => {
    const res = []
    for (let i = 0; i < 25; i += 1) {
      res.push(i)
    }
    return res
  }, [])

  const ref = useRef<Group>(null)
  // useFrame(({ clock: { elapsedTime } }) => {
  //   if (!ref.current) return
  //   ref.current.rotation.y = 0.15 * Math.sin(elapsedTime)
  // })
  // move entire group when finished dev
  const mounted = useMounted(600)
  return (
    <group ref={ref} scale={[4.5, 4.5, 4.5]} position={[0, 8.2, -11.5]}>
      <DreiText color={textColor} position={[0, 1.4, 0]} fontSize={0.2}>
        {(hovered || selected)?.snippet?.title || ''}
      </DreiText>
      {slots.map((_, i) => (
        <PlaylistItemView
          mounted={mounted}
          onEnter={() => onHover(i)}
          onLeave={() => onHover(null)}
          onClick={() => onSelect(i)}
          loading={isFetching}
          key={i}
          item={items?.[i] || null}
          pageIndex={pageIndex}
          index={i}
        />
      ))}
      {/* <Frame /> */}
      <PlaylistControls />
    </group>
  )
}
