import { FC, HTMLProps } from 'react'

export const ShadowText: FC<HTMLProps<HTMLSpanElement>> = ({
  style,
  ...props
}) => (
  <span
    style={{
      textShadow: '1px 1px 2px black',
      filter: 'drop-shadow(1px 1px 3px #000000aa)',
      color: 'white',
      fontFamily: 'Gloria Hallelujah',
      ...style,
    }}
    {...props}
  />
)
