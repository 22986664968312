import { DBUser } from '@loneworld/shared'
import { onAuthStateChanged, signOut as fbSignOut, User } from 'firebase/auth'
import { onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'
import { auth } from '../backend/auth'
import { getUserDocRef } from '../backend/db'

export type UserData = {
  user: User | null
  data: DBUser | null
  unsubscribe: null | (() => void)
  isAdmin: boolean
  loading: boolean
}

export const useUser = create<UserData>(() => ({
  user: null,
  data: null,
  loading: false,
  unsubscribe: null,
  isAdmin: false,
}))
onAuthStateChanged(auth, (newUser) => {
  useUser.setState({
    user: newUser,
    loading: true,
    unsubscribe: newUser ? onSnapshot(getUserDocRef(newUser.uid), {
      next: (res) => {
        const data = res.data()
        useUser.setState({
          data,
          isAdmin: data?.role === 'admin',
          loading: false,
        })
      },
      error: (e) => {
        console.log(e)
      },
    }) : null,
  })
})

export const signOut = () => fbSignOut(auth).then(() => useUser.setState({
  user: null,
  isAdmin: false,
}))
